import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'antd'
import { css } from 'emotion'
import { DateTime } from 'luxon'
import CraftByCleverse from './CraftByCleverse.js'

const StyledCraftByCleverse = styled(CraftByCleverse)`
  padding-right: 1rem;
  span {
    color: white;
  }
  [data-class='cleverse-white'] {
    visibility: visible !important;
  }
  [data-class='cleverse-black'] {
    visibility: hidden !important;
  }
`

const IconWrapper = styled.div``

const ModalHeader = ({
  closeModal,
  provinces,
  params,
  countPercentage,
  count,
  timestamp,
}) => {
  const provinceCode = params.province
  const province = provinces.find(p => p.provinceCode === provinceCode)
  const provinceName = province ? province.provinceName : '?'
  const date = DateTime.fromJSDate(new Date(timestamp))
  const lastUpdateAt = date.setLocale('th').toLocaleString(DateTime.DATE_MED)
  return (
    <div
      className={css`
        flex: 0 1 auto;
        background: linear-gradient(120deg, #2590d2 0%, #4c86ff 100%);
        padding: 0.25rem 0;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        height: auto;
        width: 100%;
        order: 1;
        bottom: 0;
        z-index: 999;
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          className={css`
            display: flex;
            align-items: center;
            padding: 0rem 1rem 0rem 1rem;
          `}
        >
          <IconWrapper>
            <Icon
              type="info-circle"
              style={{
                fontSize: '1.5rem',
                lineHeight: '1.5rem',
                display: 'inline-flex',
              }}
            />
          </IconWrapper>
          <div
            className={css`
              padding-left: 0.5rem;
              padding-right: 0.5rem;
              display: none;
            `}
          >
            <p>สถิติจังหวัด</p>
          </div>
          <div>
            <h4
              className={css`
                font-size: 1.602rem;
                display: none;
              `}
            >
              {provinceName}
            </h4>
          </div>
        </div>
        <div
          className={css`
            display: flex;
            align-items: center;
            padding: 0rem 1rem 0rem 1rem;
          `}
        >
          <div
            className={css`
              padding-right: 0.5rem;
              display: flex;
              align-items: baseline;
            `}
          >
            <p
              className={css`
                padding-right: 0.5rem;
              `}
            >
              นับไปแล้วทั้งหมด
            </p>{' '}
            <h4
              className={css`
                font-size: 1.602rem;
              `}
            >
              <b className={css``}>
                {count &&
                  count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                ใบ (ทั้งประเทศ)
              </b>
            </h4>
          </div>
        </div>
        <div
          className={css`
            padding: 0rem 1rem 0rem 1rem;
          `}
        >
          <p
            className={css`
              font-size: 1.245rem;
            `}
          >
            อัปเดตเมื่อ {lastUpdateAt || '??:??:??'}
          </p>
        </div>
      </div>

      <StyledCraftByCleverse />
    </div>
  )
}
export default ModalHeader
