import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from 'emotion'
import { SectionsContainer } from 'react-fullpage'
import MediaQuery from 'react-responsive'
import MapBackground from './components/assets/MAP.png'
import UpArrow from './components/assets/arrow_up_2.svg'
import DownArrow from './components/assets/arrow_down_1.svg'
import OverallPage from './pages/OverallPage'
import OverallPageMobile from './pages/OverallPageMobile'
import NewsPage from './pages/NewsPage'
import NewsPageMobile from './pages/NewsPageMobile'
import Menu from './components/Menu'
import CraftByCleverse from './components/CraftByCleverse'
import DistrictPage from './pages/DistrictPage'
import PartyListPage from './pages/PartyListPage'
import StatusBox from './components/StatusBox'
import { DataLoader } from './components/DataLoader.js'
import Headline from './components/Headline'
import SVG from 'react-inlinesvg'

import { HEADER_HEIGHT } from './components/const'
import { bpsDown, bpsUp } from './styles/const'
import ReactModal from 'react-modal'
import ModalHeader from './components/ModalHeader.js'
import ModalBody from './components/ModalBody.js'
import StatusBoxMobile from './components/StatusBoxMobile'
import Modal from 'react-modal'
import { TrackPageView } from './Analytics/TrackPageView'
import PartyDetailModal from './components/PartyDetailModal.js'

const NormalScroller = styled.div``

const Container = styled.div`
  .Navigation {
    z-index: 1001;
    left: 30px !important;
    right: unset !important;
    @media ${bpsDown.lg} {
      display: none;
    }
  }
  .slick-arrow {
    transform: rotate(90deg) translate3d(0, 20px, 0);
  }
`
const Header = styled.div`
  position: fixed;
  top: 0;
  height: ${HEADER_HEIGHT};
  width: 100%;
  display: flex;
  z-index: 302;
  @media ${bpsDown.lg} {
    background: linear-gradient(180deg, #f2f1f0, #f2f1f0 62%, #f2f1f000 100%);
  }
`
const StyledCraftByCleverse = styled(CraftByCleverse)`
  position: fixed;
  bottom: 20px;
  left: 25px;
  z-index: 302;
`
const StyledStatusBoxMobile = styled(StatusBoxMobile)`
  position: fixed;
  bottom: 0.5rem;
  width: auto;
  z-index: 303;
`
const StyledStatusBox = styled(StatusBox)`
  position: fixed;
  right: 0;
  z-index: 303;
`
const HeadlineWrapper = styled.div`
  height: ${props => (props.show ? '100%' : 0)};
  width: calc(35% - 70px);
  position: absolute;
  z-index: 299;
  top: 0;
  margin-left: 80px;
`
const MySection = styled.div`
  box-sizing: border-box;
  height: 100vh;
  position: relative;
  @media ${bpsUp.lg} {
    overflow-y: hidden;
  }
  @media ${bpsDown.lg} {
    padding-top: ${props =>
      typeof props.padding !== 'undefined' ? props.padding : HEADER_HEIGHT};
    overflow-x: hidden;
    height: auto;
    padding-bottom: 2rem;
  }
`

const ControllerContainer = styled.div`
  position: fixed;
  z-index: 302;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px #0000004a;
  padding: 1rem;
  border-radius: 0 2rem 2rem 0;
  a {
    text-decoration: none;
    color: #000;
    width: 100%;
    height: 100%;
    line-height: 2rem;
  }
`
const ControllerLi = styled.li`
  list-style-type: none;
  width: 2rem;
  ${props =>
    props.active
      ? `
    border: 4px #000 solid;
    height: 2rem;
  `
      : `
    border: none;
    height: 1.8rem;
  `};
  border-radius: 50%;
  margin: 1rem auto 1rem auto;
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.5;
  }
  h2 {
    margin: auto;
    font-weight: ${props => (props.active ? '600' : '400')};
  }
`

function PageController(props) {
  const { anchors, currAnchor } = props
  const index = anchors.indexOf(currAnchor)
  const prevAnchor = anchors[index - 1] || currAnchor
  const nextAnchor = anchors[index + 1] || currAnchor

  return (
    <ControllerContainer data-controller-bg={true}>
      <a
        href={`#${prevAnchor}`}
        className={css`
          margin: auto;
        `}
      >
        <SVG src={UpArrow} />
      </a>
      <ul
        className={css`
          flex: 1;
          padding: 0;
          margin: 0;
        `}
      >
        {anchors.map((anchor, i) => (
          <a key={i} href={`#${anchor}`}>
            <ControllerLi active={anchor === currAnchor}>
              <h2>{i + 1}</h2>
            </ControllerLi>
          </a>
        ))}
      </ul>
      <a
        href={`#${nextAnchor}`}
        className={css`
          margin: auto;
        `}
      >
        <SVG src={DownArrow} />
      </a>
    </ControllerContainer>
  )
}

export const headlineProps = {
  constituency: {
    title: 'ทั่วประเทศ',
    number: 350,
    unit: 'เขต',
    bottomText: data => {
      return (
        <div>
          <p
            className={css`
              font-size: 2vw;
              margin-top: 4vw;
              font-weight: 500;
            `}
          >
            นับคะแนนไปแล้ว{' '}
            <b>
              {data &&
                data.map &&
                data.map.overview.counted
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </b>{' '}
            ใบ
          </p>
        </div>
      )
    },
  },
  partylist: {
    prefix: 'คาดการณ์',
    title: 'บัญชีรายชื่อ (Party List)',
    number: 150,
    bottomText: () => {
      return
    },
    remark:
      'คำนวณจากผลคะแนนเลือกตั้งจริงจาก กกต. นำมาเข้าสูตรหา ส.ส.บัญชีรายชื่อตามรัฐธรรมนูญ 60',
  },
  default: {},
}

class App extends Component {
  constructor() {
    super()
    this.anchors = ['constituency', 'partylist', 'overall', 'news']
    this.state = {
      isMobile: this.isMobile(),
    }
    this.handleResize = this.handleResize.bind(this)
  }
  isMobile() {
    return window.innerWidth <= 1024
  }
  handleResize() {
    this.setState({
      isMobile: this.isMobile(),
    })
  }
  componentDidMount() {
    Modal.setAppElement(this.app)
    window.addEventListener('resize', this.handleResize)
  }
  render() {
    const isMobile = this.state.isMobile
    const Scroller = !isMobile ? SectionsContainer : NormalScroller

    let anchor = 'constituency'
    if (this.props.location.hash) {
      anchor = this.props.location.hash.slice(1) || ''
    }
    return (
      <Container
        ref={ele => {
          this.app = ele
        }}
      >
        <TrackPageView />
        {!isMobile ? (
          <MediaQuery query={bpsUp.lg}>
            <PageController anchors={this.anchors} currAnchor={anchor} />
          </MediaQuery>
        ) : (
          false
        )}

        <Header>
          <Menu />
        </Header>
        <DataLoader
          onLoaded={() => {
            if (document.getElementById('loading-screen') === 'hide') return
            document.getElementById('loading-screen').className = 'fade'
            setTimeout(() => {
              document.getElementById('loading-screen').className = 'hide'
            }, 500)
          }}
        >
          {({ loading, error, data }) => (
            <>
              <MediaQuery query={bpsUp.lg}>
                <StyledCraftByCleverse />
                <HeadlineWrapper
                  show={anchor in headlineProps}
                  className="fixed"
                >
                  <Headline
                    {...headlineProps[anchor]}
                    children={
                      headlineProps[anchor] &&
                      headlineProps[anchor].bottomText &&
                      headlineProps[anchor].bottomText(data)
                    }
                  />
                </HeadlineWrapper>
              </MediaQuery>
              <MediaQuery query={bpsDown.lg}>
                <StyledStatusBoxMobile
                  loading={loading}
                  error={error}
                  data={data}
                />
              </MediaQuery>
              <MediaQuery query={bpsUp.lg}>
                <StyledStatusBox loading={loading} error={error} data={data} />
              </MediaQuery>
              <Scroller
                activeSection={this.anchors.indexOf(anchor)}
                anchors={this.anchors}
                sectionClassName="my-section"
                delay={500}
                scrollCallback={this.scrollCallback}
                navigation={false}
                // scrollCallback={this.scrollCallback}
              >
                <MySection
                  className={`my-section ${css`
                    position: relative;
                  `}`}
                >
                  <>
                    {' '}
                    {data && (
                      <ReactModal
                        isOpen={this.props.match.params.modal === 'district'}
                        // onAfterOpen={}
                        // style={}
                        // data={}

                        style={{
                          overlay: {
                            zIndex: '3000',
                            overflow: 'hidden',
                            backgroundColor: 'rgba(0, 0, 0, 0.55)',
                            height: '100%',
                          },
                          content: {
                            top: '0',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            padding: '0',
                            border: '0',
                            borderRadius: '0',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'rgba(0, 0, 0, 0.35)',
                          },
                        }}
                      >
                        <ModalHeader
                          provinces={data.map.provinces}
                          params={this.props.match.params}
                          countPercentage={data.map.overview.percentage}
                          count={data.map.overview.counted}
                          timestamp={data.timestamp}
                        />
                        <ModalBody
                          provinces={data.map.provinces}
                          timestamp={data.timestamp}
                          params={this.props.match.params}
                          closeModal={() =>
                            this.props.history.push({
                              pathname: '/',
                              hash: this.props.history.location.hash,
                            })
                          }
                        />
                      </ReactModal>
                    )}
                    {data && (
                      <ReactModal
                        isOpen={this.props.match.params.modal === 'party'}
                        style={{
                          overlay: {
                            zIndex: '3000',
                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                            height: '100%',
                            overflow: 'hidden',
                          },
                          content: {
                            top: '0',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            padding: '0',
                            border: '0',
                            borderRadius: '0',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                          },
                        }}
                      >
                        <PartyDetailModal
                          data={data}
                          params={this.props.match.params}
                          closeModal={() =>
                            this.props.history.push({
                              pathname: '/',
                              hash: this.props.history.location.hash,
                            })
                          }
                          ranking={data.map.overview.ranking}
                          provinces={data.map.provinces}
                          isMobile={isMobile}
                          partylistHidden={data.partylistHidden}
                        />
                      </ReactModal>
                    )}
                    <DistrictPage
                      loading={loading}
                      error={error}
                      data={data}
                      isFocus={anchor === 'constituency'}
                    />
                  </>
                  <img
                    src={MapBackground}
                    alt="background"
                    className={css`
                      z-index: 1;
                      position: absolute;
                      top: 5rem;
                      bottom: 0;
                      height: calc(100vh - 19rem);
                      object-fit: contain;
                      object-position: center center;
                    `}
                  />
                </MySection>
                <MySection
                  className={css`
                    @media ${bpsDown.lg} {
                      background: rgba(0, 0, 0, 0.05);
                      padding-bottom: 0;
                      border-radius: 36px 36px 0px 0px;
                      padding-top: 1rem;
                    }
                    overflow: hidden;
                  `}
                >
                  <PartyListPage
                    loading={loading}
                    error={error}
                    data={data}
                    isFocus={anchor === 'partylist'}
                  />
                </MySection>
                <MySection className="my-section">
                  {isMobile ? (
                    <OverallPageMobile
                      loading={loading}
                      error={error}
                      data={data}
                      isFocus={anchor === 'overall'}
                    />
                  ) : (
                    <OverallPage
                      loading={loading}
                      error={error}
                      data={data}
                      isFocus={anchor === 'overall'}
                    />
                  )}
                </MySection>
                <MySection
                  className={css`
                  z-index:${isMobile ? 999 : ''};
                  padding-bottom:0;
                  overflow:hidden;
                };
                `}
                  padding={0}
                >
                  {isMobile ? (
                    <NewsPageMobile
                      isFocus={anchor === 'news'}
                      loading={loading}
                      error={error}
                      data={data}
                    />
                  ) : (
                    <NewsPage
                      isFocus={anchor === 'news'}
                      loading={loading}
                      error={error}
                      data={data}
                    />
                  )}
                </MySection>
              </Scroller>
            </>
          )}
        </DataLoader>
      </Container>
    )
  }
}

export default App
