import React, { useState } from 'react'
import _ from 'lodash'
import SinglePolygon from '../components/SinglePolygon'
import { css } from 'emotion'
import styled from '@emotion/styled'
import HeadlineSm from '../components/HeadlineSm'
import { MaxWidth } from '../components/const'
import contrast from 'contrast'
import { bpsDown } from '../styles/const'
import { pushRoute } from '../routing.js'

const ShowAllButton = styled.button`
  display: none;
  @media ${bpsDown.lg} {
    padding: 10px 20px;
    border-radius: 10px;
    font-family: 'db_heavent';
    font-size: 24px;
    font-weight: 900;
    background: white;
    border: 0;
    display: block;
    margin: 10px auto 0;
  }
`

const OverallPageMobile = ({ loading, error, data, isFocus }) => {
  const [showAll, setShowAll] = useState(false)
  if (!data) return 'Loading...'
  const overallData = data.partylistHidden ? data.pre70Overall : data.overall
  const overall = _.filter(overallData, d => d.seats > 0)
  return (
    <div>
      <HeadlineSm title="รวมทั้งหมด" prefix="คาดการณ์" number={500} />
      <MaxWidth>
        <div
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            className={css`
              width: 100%;
              background: white;
              border-radius: 25px;
              margin-top: 1rem;
              overflow: hidden;
              & > *:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              }
            `}
          >
            {overall.map((data, i) => (
              <div
                onClick={() => {
                  if (!data.partylistHidden) {
                    pushRoute('/party/' + data.partyCode)
                  }
                }}
                className={css`
                  display: flex;
                  padding: 1rem 1.5rem;
                  align-items: center;
                  justify-content: space-between;
                  @media ${bpsDown} {
                    display: ${i > 6 && !showAll ? 'none' : 'flex'};
                  }
                `}
                key={data.partyName}
              >
                <div
                  className={css`
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: center;
                  `}
                >
                  <h3
                    className={css`
                      display: block;
                      font-size: 1.6rem;
                      margin-bottom: 0.25rem;
                      font-weight: 500;
                    `}
                  >
                    {data.partyName}
                  </h3>
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <div
                      className={css`
                        display: inline-block;
                        position: relative;
                      `}
                    >
                      <SinglePolygon
                        style={{
                          position: 'relative',
                          width: '2rem',
                          height: '2rem',
                        }}
                        color={data.color}
                      />
                      <p
                        className={css`
                          display: block;
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                          color: white;
                          margin-top: 7%;
                          font-weight: 900;
                          color: ${contrast(data.color) === 'light'
                            ? 'rgba(0,0,0,0.5)'
                            : 'white'};
                        `}
                      >
                        {data.seats}
                      </p>
                    </div>
                    <span
                      className={css`
                        margin-left: 0.5rem;
                        font-size: 1.25rem;
                        font-weight: 500;
                        color: ${data.color};
                      `}
                    >
                      ที่นั่ง
                    </span>
                  </div>
                </div>

                <div
                  className={css`
                    margin-right: 1rem;
                    width: 4rem;
                    height: 5rem;
                    display: inline-block;
                    background-size: contain;
                    background-image: url(${data.picture});
                    background-position: center;
                    background-repeat: no-repeat;
                  `}
                />
              </div>
            ))}
          </div>
          {!showAll && overall.length > 6 && (
            <ShowAllButton onClick={() => setShowAll(true)}>
              แสดงผลทั้งหมด
            </ShowAllButton>
          )}
          {overall.length === 0 && (
            <div
              className={css`
                text-align: center;
                font-weight: 900;
                font-size: 24px;
                color: #888;
              `}
            >
              ยังไม่มีผู้สมัครที่ได้รับคะแนนถึงเกณฑ์ที่กำหนด
            </div>
          )}
        </div>
      </MaxWidth>
    </div>
  )
}
export default OverallPageMobile
