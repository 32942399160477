import { Component } from 'react'
import { client } from '../api.js'

const ONE_MINUTES_IN_MS = 60000
const dataUrl = 'https://cdn.vote.workpointnews.com/data/latest.json'
const versionUrl = 'https://cdn.vote.workpointnews.com/data/version.json'
let currentData

export class DataLoader extends Component {
  state = {}
  async fetchData() {
    try {
      this.setState({ loading: true, error: null })
      const { data } = await client.get(dataUrl)
      // console.log(
      //   'url result',
      //   { hash: data.hash, timestamp: data.timestamp },
      //   data,
      // )
      currentData = data
      this.setState({ loading: false, data, error: null })
    } catch (e) {
      console.log(e)
      this.setState({ loading: false, error: e })
    } finally {
      this.props.onLoaded && this.props.onLoaded()
    }
  }
  async checkVersion() {
    const { data } = await client.get(versionUrl)
    // console.log('version result', data)
    if (data.hash !== currentData.hash) {
      await this.fetchData()
    } else {
      const lastUpdate = data.timestamp
      currentData.timestamp = lastUpdate
      this.setState({ data: currentData })
    }
  }
  async componentDidMount() {
    await this.fetchData()
    setInterval(async () => {
      await this.checkVersion()
      // await this.fetchData()
    }, ONE_MINUTES_IN_MS)
  }
  render() {
    const { loading, data, error } = this.state
    return this.props.children({ loading, data, error })
  }
}

export default DataLoader
