export const getHexWidthHeight = sideLength => {
  const height = sideLength * 2
  const width = (height * Math.sqrt(3)) / 2
  return { height, width }
}
export const getHexPointArray = (width, height) => {
  const a = [width / 2, 0]
  const b = [width, height / 4]
  const c = [width, (height * 3) / 4]
  const d = [width / 2, height]
  const e = [0, (height * 3) / 4]
  const f = [0, height / 4]
  return [a, b, c, d, e, f]
}
export const addManyHexToSvg = (
  svg,
  size,
  amount,
  columnSize,
  isVertical,
  widthOffset = 0,
  heightOffset = 0,
  onClick,
  onMouseOver,
  onMouseOut,
) => {
  const { width, height } = getHexWidthHeight(size)
  const hexPointArray = getHexPointArray(width, height)
  for (var i = 0; i < amount; i++) {
    let offsetX, offsetY
    if (isVertical) {
      offsetX = Math.floor(i / columnSize) * (width + widthOffset)
      offsetY = (i % columnSize) * (height + heightOffset)
    } else {
      offsetX = (i % columnSize) * (width + widthOffset)
      offsetY = Math.floor(i / columnSize) * (height + heightOffset)
    }

    const currentHexPointArray = hexPointArray.reduce((r, e) => {
      r.push(e[0] + offsetX, e[1] + offsetY)
      return r
    }, [])
    svg
      .append('polygon')
      .style('fill', '#dddddd')
      .attr('points', currentHexPointArray.join(' '))
      .attr('i', i)
    if (onClick) {
      svg.on('click', onClick)
    }
    if (onMouseOver) {
      svg.on('mouseover', onMouseOver)
    }
    if (onMouseOut) {
      svg.on('mouseout', onMouseOut)
    }
  }
}
