import React from 'react'
import cleverseLogo from './assets/cleverse-logo-black.png'
import cleverseLogoWhite from './assets/cleverse-logo-white.png'
import { css } from 'emotion'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const CraftByCleverse = ({ className }) => (
  <Container className={className}>
    <span
      className={css`
        color: #939292;
        font-weight: 600;
        font-size: 21px;
        margin-right: 10px;
      `}
    >
      พัฒนาโดย
    </span>
    <a
      target="_blank noopener noreferrer"
      href="https://www.facebook.com/cleverse.team"
    >
      <span
        className={css`
          position: relative;
          display: inline-block;
        `}
      >
        <img
          data-class={'cleverse-black'}
          src={cleverseLogo}
          alt="cleverse logo"
          height="32"
        />
        <img
          data-class={'cleverse-white'}
          className={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          `}
          src={cleverseLogoWhite}
          alt="Cleverse"
        />
      </span>
    </a>
  </Container>
)

export default CraftByCleverse
