import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from 'emotion'
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  font-weight: 600;
  margin-bottom: 4vw;
  .title {
    font-size: 1.5vw;
    color: #7d7d7d;
    margin-bottom: 3vw;
  }
  .number {
    font-size: 8.5vw;
    line-height: 7vw;
  }
  .remark {
    color: #7d7d7d;
    font-size: 18px;
    max-width: 320px;
  }
`

class Headline extends Component {
  render() {
    const { prefix, title, number, remark, unit } = this.props
    if (!number) return null
    return (
      <Container>
        <div className="title">
          <span
            className={css`
              display: inline-block;
            `}
          >
            {prefix}
          </span>
          <span
            className={css`
              display: inline-block;
            `}
          >
            ผลเลือกตั้ง ส.ส.
          </span>
          <span
            className={css`
              display: inline-block;
            `}
          >
            {title}
          </span>
        </div>
        <div className="number">
          {number} {unit || 'ที่นั่ง'}
        </div>
        <div className="remark">
          {remark || '*ข้อมูลที่ท่านเห็นเป็นข้อมูลทางการจาก กกต.'}
        </div>
        {this.props.children}
      </Container>
    )
  }
}

export default Headline
