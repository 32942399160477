import React from 'react'
import _ from 'lodash'
import Slider from 'react-slick'
import styled from '@emotion/styled'
import SinglePolygon from '../components/SinglePolygon'
import { pushRoute } from '../routing.js'
import { bpsDown } from '../styles/const'

const PartyCardWrapper = styled.div`
  cursor: pointer;
  :hover {
    box-shadow: 0 5px 10px 0 rgba(88, 88, 88, 0.2);
  }
  height: 5.5rem;
  background-color: white;
  border-radius: 10px;
  margin: 0.75rem 0;
  display: flex;
  position: relative;
  @media ${bpsDown.lg} {
    height: 90px;
    margin: 8px 0;
  }
  .logo-wrapper {
    width: 40%;
    flex-grow: 5;
    padding: 0.75rem;
    box-sizing: border-box;
    img {
      height: 100%;
      margin: 0 auto;
    }
  }
  .content-wrapper {
    width: 60%;
    flex-grow: 5;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .party-name {
      color: #7d7d7d;
      font-weight: 500;
      font-size: 1.25rem;
    }
    .seats-count {
      color: #454545;
      font-size: 3.8rem;
      font-weight: 700;
      margin: 0;
      line-height: 0.8;
    }
  }
`

const PartyCard = ({ data, partylistHidden }) => (
  <PartyCardWrapper
    onClick={() => {
      if (!partylistHidden) {
        pushRoute('/party/' + data.partyCode)
      }
    }}
  >
    <div className="logo-wrapper">
      <img alt={`${data.partyName} Logo`} src={data.picture} />
    </div>
    <div className="content-wrapper">
      <p className="party-name">{data.partyName}</p>
      <p className="seats-count">{data.seats}</p>
    </div>
    <SinglePolygon
      style={{
        width: '22px',
        height: '22px',
        position: 'absolute',
        bottom: '8px',
        right: '8px',
      }}
      color={data.color}
    />
  </PartyCardWrapper>
)
export const OverallSidebarContainer = ({ data, partylistHidden }) => (
  <div style={{ maxWidth: '55vw', alignSelf: 'center', width: '300px' }}>
    <Slider vertical={true} rows={4} swipe={true} infinite={false}>
      {/* .filter(p => p.seats > 0) */}
      {_.map(data, party => (
        <PartyCard
          key={party.partyName}
          data={party}
          partylistHidden={partylistHidden}
        />
      ))}
    </Slider>
  </div>
)

export default OverallSidebarContainer
