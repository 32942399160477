import React, { useEffect, useState } from 'react'
import MediaQuery from 'react-responsive'
import HeadlineSm from '../components/HeadlineSm'
import Template from '../components/Template'
import PartyListResult from '../components/PartyList/PartyListResult'
import styled from '@emotion/styled'
import { css } from 'emotion'
import { bpsDown } from '../styles/const'
import { headlineProps } from '../App'

const HiddenPartyListWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HiddenPartyListInner = styled.div`
  background: white;
  border-radius: 30px;

  @media ${bpsDown.lg} {
    border-radius: 30px 30px 30px 30px;
    margin: 10px;
  }

  padding: 30px;
  max-width: 400px;
  .top {
    font-size: 1.85rem;
    padding-bottom: 2rem;
    padding-top: 0.5rem;
    border-bottom: 1px solid #0000001a;
    margin-bottom: 1rem;
    font-weight: 500;
    text-align: center;
  }
  .bottom {
    padding-top: 15px 0;
    text-align: center;
    .counted {
      font-size: 20px;
      font-weight: 700;
      span {
        color: #4688f8;
        font-size: 35px;
        font-weight: 500;
        position: relative;
        top: 3px;
        left: 3px;
      }
    }
    .subtitle {
      color: #888888;
      font-weight: 500;
    }
  }
`
const ProgressBarWrapper = styled.div`
  height: ${props => props.height}px;
  border-radius: ${props => props.height / 2}px;
  background: #ededed;
  max-width: ${props => props.maxWidth}px;
  margin: 0 auto;
`
const ProgressBarInner = styled.div`
  height: 100%;
  background: #4688f8;
  width: ${props => props.percentage}%;
  border-radius: ${props => props.height / 2}px;
  transition: width 1s;
`
const ProgressBar = ({ percentage, maxWidth = 350, height = 25 }) => (
  <ProgressBarWrapper maxWidth={maxWidth} height={height}>
    <ProgressBarInner percentage={percentage} height={height} />
  </ProgressBarWrapper>
)

const thaiNum = num => {
  if (num >= 1000000) {
    return `${Math.floor(num / 1000000)} ล้าน`
  }
  if (num >= 100000) {
    return `${Math.floor(num / 100000)} แสน`
  }
  if (num >= 10000) {
    return `${Math.floor(num / 10000)} หมื่น`
  }
  if (num >= 1000) {
    return `${Math.floor(num / 1000)} พัน`
  }
  if (num >= 100) {
    return `${Math.floor(num / 100)} ร้อย`
  }
  return num + ' '
}

const HiddenPartyList = ({
  counted = 0,
  totalVotes = 100,
  percentage,
  isFocus,
}) => {
  const [movingPercentage, setMovingPercentage] = useState(0)
  useEffect(() => {
    if (isFocus) {
      setMovingPercentage(percentage)
    } else {
      setMovingPercentage(0)
    }
  }, [isFocus])
  return (
    <HiddenPartyListWrapper>
      <HiddenPartyListInner>
        <div className="top">
          ระบบจะแสดงผลบัญชีรายชื่อหลังนับคะแนนไปแล้วมากกว่า 70% ของผู้มาใช้สิทธิ
        </div>
        <div className="bottom">
          <p className="counted">
            นับคะแนนไปแล้ว <span>{Math.floor(percentage)}%</span>
          </p>
          <ProgressBar percentage={movingPercentage} />
          <p className="subtitle">
            {thaiNum(counted)}เสียง จาก {thaiNum(totalVotes)}เสียง
          </p>
        </div>
      </HiddenPartyListInner>
    </HiddenPartyListWrapper>
  )
}

const PartyListResultWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0;
  box-sizing: border-box;

  @media ${bpsDown.lg} {
    margin-top: 0.5rem;
    padding: 0;
    margin-bottom: 1rem;
  }
`
const HeadlineWrapper = styled.div``
const PartyListPage = ({ loading, error, data, isFocus }) => (
  <Template.Container>
    <Template.Left>
      <MediaQuery query={bpsDown.lg}>
        <HeadlineWrapper>
          <HeadlineSm {...headlineProps.partylist} />
        </HeadlineWrapper>
      </MediaQuery>
    </Template.Left>
    <Template.Item
      className={css`
        flex-basis: 65%;
        padding: 2rem 0;
        @media ${bpsDown.lg} {
          overflow: scroll;
          max-width: 25rem;
          width: 100%;
          margin: auto;
        }
      `}
    >
      <PartyListResultWrapper>
        {data && data.partylistHidden ? (
          <HiddenPartyList isFocus={isFocus} {...data.map.overview} />
        ) : (
          <PartyListResult loading={loading} error={error} data={data} />
        )}
      </PartyListResultWrapper>
    </Template.Item>
  </Template.Container>
)

export default PartyListPage
