import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from 'emotion'
import SinglePolygon from '../SinglePolygon'
import { pushRoute } from '../../routing.js'
import contrast from 'contrast'
import Slider from 'react-slick'

const CardContent = styled.div`
  cursor: pointer;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
  i {
    margin: auto;
    display: inline-flex;
    color: #ccc;
    width: 10%;
  }
  .rank {
    color: #000000;
    font-size: 1rem;
    opacity: 0.65;

    margin: 0;
  }
  :hover {
    box-shadow: 0 5px 15px 0 rgba(88, 88, 88, 0.1);
  }
`

const ImgContainer = styled.div`
  max-width: 90px;
  height: 100%;
  overflow: hidden;
  margin-right: 4%;
  width: 30%;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
`
const NameParty = styled.h2`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ActualNameParty = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const MemberParty = styled.h1`
  font-size: 2.424rem;
  line-height: 2rem;
  margin: auto;
  margin-right: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ::after {
    font-weight: 500;
    content: 'ที่นั่ง';
    font-size: 1rem;
    color: #999;
    margin-left: auto;
    margin-top: -0.5rem;
    white-space: nowrap;
  }
  ::before {
    font-weight: 500;
    content: 'รวม (เขต/บัญชีรายชื่อ)';
    font-size: 1rem;
    color: #999;
    margin-left: auto;
    margin-top: -0.5rem;
    white-space: nowrap;
  }
  p {
    margin: 0;
    font-size: 2.424rem;
  }
`

const ZoneCardWrapper = styled.div`
  margin-top: 2rem;
  .header {
    display: flex;
    flex-direction: row;
    background-color: ${props => props.headerColor};
    padding: 15px;
    min-height: 5rem;
    border-radius: 10px 10px 0 0;
    .logo {
      position: relative;
      display: flex;
      display: flex;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      img {
        width: 100%;
        object-fit: contain;
        height: auto;
        top: 50%;
        position: relative;
      }
      .inner {
        z-index: 1;
      }
      .polygon {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 4rem;
        height: auto;
        svg {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
    .district {
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      margin-right: 1.5rem;
      flex-grow: 1;
      p {
        margin: 0;
        font-weight: 900;
        color: ${props =>
          contrast(props.headerColor) === 'light' ? 'black' : 'white'};
      }
      .province {
        font-weight: 700;
        opacity: 0.5;
      }
      .zone-desc {
        opacity: 1;
        span {
          display: inline-block;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.25rem;
          margin-top: 0.5rem;
        }
      }
    }
    .district-no-wrapper {
      justify-content: center;
      text-align: center;
      font-weight: 900;
      color: ${props =>
        contrast(props.headerColor) === 'light' ? 'black' : 'white'};
      display: flex;
      flex-direction: column;
      .district-no {
        font-size: 50px;
        line-height: 34px;
      }
      p {
        margin: 0;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: white;
    border-radius: 0 0 10px 10px;
    & > .candidate:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .candidate {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        margin-right: 8px;
      }
      img.party {
        object-fit: contain;
      }
      .name {
        flex-grow: 1;
        font-size: 20px;
      }
      .score-wrapper {
        flex-grow: 1;
        text-align: right;
        .score {
          font-size: 1.5rem;
          margin-left: 2rem;
        }
      }
    }
    .candidate.highlight {
      .name,
      .score {
        color: black;
      }
      .score {
        font-size: 2.25rem;
        line-height: 1.5rem;
      }
    }
    p {
      margin: 0;
      color: #00000094;
      font-weight: 500;
      line-height: 1rem;
    }
  }
`
const ZoneCard = ({ zone }) => {
  const { topCandidates, zoneNo, zoneDesc, province } = zone
  const area = zoneDesc.map((desc, index) => <span key={index}>{desc}</span>)
  return (
    <ZoneCardWrapper headerColor={topCandidates[0].color}>
      <div className="header">
        <div className="logo">
          <SinglePolygon className="polygon" size={40} color="#fff">
            <div
              className={css`
                background-image: url(${topCandidates[0].partyPic});
                position: relative;
                top: 0rem;
                height: 2.7rem;
                margin: auto;
                width: 3rem;
                left: 0;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
              `}
            />
          </SinglePolygon>
        </div>
        <div className="district">
          <p className="province">{province.provinceName}</p>
          <p className="zone-desc">{area}</p>
        </div>
        <div className="district-no-wrapper">
          <p>เขต</p>
          <p className="district-no">{zoneNo}</p>
        </div>
      </div>
      <div className="content">
        {topCandidates.map((candidate, index) => (
          <div
            key={candidate.partyCode}
            className={`candidate ${index === 0 ? 'highlight' : ''}`}
          >
            <img className="party" src={candidate.partyPic} alt="party" />
            <img
              className="candidate"
              src={candidate.picture}
              alt="candidate"
            />
            <p className="name">{candidate.candidate}</p>
            <div className="score-wrapper">
              <p className="score">
                {candidate.score
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </p>
              <p>เสียง</p>
            </div>
          </div>
        ))}
      </div>
    </ZoneCardWrapper>
  )
}

class CardMemberMapElection extends Component {
  render() {
    return (
      <div
        className={css`
          text-decoration: none;
          color: initial;
          height: 7rem;
          ::after {
            content: '';
            width: 100%;
            display: block;
            border-bottom: 1px #eee solid;
          }
          .polygon-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            p {
              font-weight: 500;
              font-size: 16px;
              color: #999;
            }
          }
          h1.display {
            line-height: 1.5rem;
            p {
              display: flex;
            }
          }

          .sub-seat {
            font-size: 1rem;
            margin-left: 0.31rem;
            font-weight: 600;
            color: #999;
          }
        `}
      >
        <CardContent
          onClick={() => pushRoute('/party/' + this.props.partyCode)}
        >
          <ImgContainer>
            <img alt={this.props.partyName} src={this.props.partyPic} />
          </ImgContainer>
          <NameParty className="display">
            <p className="rank">อันดับที่ {this.props.rank}</p>
            <ActualNameParty>{this.props.partyName}</ActualNameParty>
            <div className="polygon-container">
              <SinglePolygon
                style={{
                  width: '22px',
                  height: '28px',
                  marginRight: '10px',
                }}
                color={this.props.color}
              />
              <p>
                คะแนนรวม{' '}
                <strong>
                  {this.props.votes
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </strong>{' '}
                เสียง
              </p>
            </div>
          </NameParty>
          <MemberParty className="display">
            <p>
              {this.props.constituencySeats + this.props.partylistSeats}{' '}
              <span className="sub-seat">
                ({this.props.constituencySeats}/{this.props.partylistSeats})
              </span>
            </p>
          </MemberParty>
        </CardContent>
      </div>
    )
  }
}

export default class CardElectionContainer extends Component {
  render() {
    const { ranking, zone, hover, overall } = this.props
    let currentRank = 0
    let currentSeat = 0
    if (hover && zone) {
      return (
        <div
          className={`${css`
            align-self: center;

            width: 100%;
            max-width: 26rem;
          `}`}
        >
          <ZoneCard zone={zone} />
        </div>
      )
    }
    return (
      <div
        onWheel={e => {
          e.stopPropagation()
        }}
        onTouchMove={e => {
          e.stopPropagation()
        }}
        className={`${css`
          align-self: center;
          width: 30vw;
          max-width: 26rem;
        `}`}
      >
        <Slider
          vertical={true}
          rows={window.innerHeight > 800 ? 5 : 4}
          swipe={true}
          infinite={false}
          className={css`
            background-color: white;
            padding: 1rem;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            vertical-align: middle;

            .slick-prev {
              top: -50px;
            }
            .slick-next {
              bottom: -50px;
            }
          `}
        >
          {(overall || []).map((o, i) => {
            let rank = i + 1
            if (currentSeat === o.seats) {
              rank = currentRank
            }
            currentSeat = o.seats
            currentRank = rank
            const rankingItem = ranking.find(r => r.partyCode === o.partyCode)
            return (
              <CardMemberMapElection
                key={o.partyName}
                {...o}
                {...rankingItem}
                rank={rank}
              />
            )
          })}
        </Slider>
      </div>
    )
  }
}
