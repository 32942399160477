import styled from '@emotion/styled'
import * as d3 from 'd3'
import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'

import SinglePolygon from '../components/SinglePolygon'
import calculatorImage from '../components/assets/calculator.png'
import { pushRoute } from '../routing.js'
import { getHexWidthHeight, getHexPointArray } from '../util/hexagonUtil'
import { bpsUp } from '../styles/const'

const hexSideLength = 25
const hexAmount = 500
const columnSize = 10
const widthOffset = 7
const heightOffset = 10

// const handleMouseOverPolygon = (d, i, group) => {
//   const polygon = group[i]
// }
// const handleMouseOutPolygon = (d, i, group) => {
//   const polygon = group[i]
// }

const Wrapper = styled.div`
  max-width: calc(70vw - 14rem);
  height: auto;
  margin: 0 auto;
  svg {
    height: 100%;
  }
  rect {
    cursor: pointer;
  }

  .note {
    color: #999;
    background-image: url(${calculatorImage});
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: right center;
    padding-right: 40px;
    text-align: right;
    max-width: 215px;
    margin-top: 30px;
    float: right;
    white-space: pre-wrap;
  }
`

const prepareHexData = data => {
  const reps = _.flatten(_.map(data, row => _.fill(Array(row.seats), row))).map(
    (rep, index) => ({ ...rep, index }),
  )
  const repsCloned = _.clone(reps)
  const filled = _.map(_.range(hexAmount - repsCloned.length), o => ({
    color: '#dddddd',
    partyCode: '___',
  }))
  const entries = _.map([...reps, ...filled], (d, i) => ({ ...d, index: i }))
  return d3
    .nest()
    .key(d => d.partyCode)
    .entries(entries)
}

const PartyTooltipWrapper = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  text-align: left;
  position: relative;
  .header {
    display: flex;
    align-items: center;
    img {
      height: 40px;
      margin-right: 10px;
      float: left;
    }
    span {
      font-size: 20px;
      font-weight: 900;
    }
  }
  p {
    margin: 0;
  }
  p span {
    color: black;
    font-size: 20px;
    font-weight: 900;
  }
  .label {
    font-weight: 500;
    color: #999;
    margin: 7px 0 0 0;
  }
  p.vote,
  p.seats {
    color: #888;
    font-weight: 900;
  }
  .profile-stack {
    margin-top: 7px;
    display: flex;
    img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin-left: -4px;
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.27);
      position: relative;
    }
    .last-profile {
      font-weight: 900;
      font-size: 15px;
      margin-left: -4px;
      text-align: center;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      position: relative;
      display: inline-block;
      background: #ccc;
      color: #fff;
    }
  }
`
const PartyTooltip = props => (
  <PartyTooltipWrapper>
    <div className="header">
      <img alt={props.partyName} src={props.picture} />
      <span>{props.partyName}</span>
    </div>
    {props.mode === 'normal' && (
      <div className="profile-stack">
        {_.take(
          props.partylistCandidates.concat(props.constituencyCandidates),
          props.constituencyCandidates.length +
            props.partylistCandidates.length >
            5
            ? 4
            : 5,
        ).map((c, i) => (
          <img
            style={{ zIndex: `${1000 - i}` }}
            key={c.candidate}
            alt="candidate photos"
            src={c.picture}
          />
        ))}
        {props.constituencyCandidates.length +
          props.partylistCandidates.length >
          5 && (
          <div className="last-profile">
            +
            {props.constituencyCandidates.length +
              props.partylistCandidates.length -
              4}
          </div>
        )}
      </div>
    )}
    <p className="label">คะแนนเสียงทั่วประเทศ</p>
    <p className="vote">
      <span>
        {props.votes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>{' '}
      เสียง
    </p>
    <p className="label">ได้ ส.ส. ในสภา</p>
    <p className="seats">
      <span>{props.seats}</span> ที่นั่ง (แบ่งเขต{' '}
      {props.constituencyCandidates.length} ที่นั่ง / บัญชีรายชื่อ{' '}
      {props.partylistCandidates.length} ที่นั่ง)
    </p>
    <SinglePolygon
      style={{
        width: '22px',
        height: '22px',
        position: 'absolute',
        top: '8px',
        right: 0,
      }}
      color={props.color}
    />
  </PartyTooltipWrapper>
)

class OverallPolygon extends Component {
  handleMouseClickPolygon = (d, i, group) => {
    //   const polygon = d3.select(group[i])
    //   const index = polygon.attr('i')
    //   const color = getRandomColor
    //   polygon.style('fill', color)
    // const svg = d3.select(this.svg)
    // svg.selectAll('polygon').style('fill', 'black')
  }
  componentDidMount() {
    this.updateData()
    this.play()
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.isFocus && this.props.isFocus) {
      this.play()
    } else {
      this.updateData()
    }
  }

  updateData = () => {
    if (this.playing) return
    const data = prepareHexData(this.props.data)
    const { width, height } = getHexWidthHeight(hexSideLength)
    const hexPoints = getHexPointArray(width, height).join(' ')
    const groups = d3
      .select(this.svg)
      .selectAll('g')
      .data(data, d => d.key)
    groups.exit().remove()
    const mergedGroups = groups
      .enter()
      .append('g')
      .merge(groups)
      .attr('data-tip', d => (d.key !== '___' ? d.key : null))
      .attr('data-for', d => (d.key !== '___' ? 'overall-tooltip' : null))
      .on('click', d => {
        if (d.key !== '___' && this.props.mode === 'normal')
          pushRoute('/party/' + d.key)
      })
    const polygons = mergedGroups.selectAll('polygon').data(d => {
      return d.values
    })
    polygons.exit().remove()
    polygons
      .enter()
      .append('polygon')
      .merge(polygons)
      .style('fill', d => {
        return d.color
      })
      .attr('points', hexPoints)
      .transition()
      .duration(1000)
      .ease(d3.easeExpOut)
      .attr('transform', (d, i) => {
        const offsetX = Math.floor(d.index / columnSize) * (width + widthOffset)
        const offsetY = (d.index % columnSize) * (height + heightOffset)
        if (this.props.isMobile)
          return `translate(${offsetY} ${offsetX}) scale(1)`
        return `translate(${offsetX} ${offsetY}) scale(1)`
      })
    const rects = mergedGroups.selectAll('rect').data(d => {
      return d.values
    })
    rects.exit().remove()
    rects
      .enter()
      .append('rect')
      .merge(rects)
      .attr('width', width + widthOffset)
      .attr('height', height + heightOffset)
      .style('opacity', 0)
      .transition()
      .duration(1000)
      .ease(d3.easeExpOut)
      .attr('transform', (d, i) => {
        const offsetX = Math.floor(d.index / columnSize) * (width + widthOffset)
        const offsetY = (d.index % columnSize) * (height + heightOffset)
        if (this.props.isMobile)
          return `translate(${offsetY} ${offsetX}) scale(1)`
        return `translate(${offsetX} ${offsetY}) scale(1)`
      })

    groups.exit().remove()
    ReactTooltip.rebuild()
  }
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.playing = false
      this.timeout = null
    }
  }
  play = () => {
    if (this.playing) return
    this.playing = true
    this.timout = setTimeout(() => {
      this.playing = false
      this.updateData()
    }, 3000)
    const { width, height } = getHexWidthHeight(hexSideLength)
    d3.select(this.svg)
      .selectAll('polygon')
      .interrupt()
    d3.select(this.svg)
      .selectAll('polygon')
      .attr('transform', (_, i) => {
        const offsetX =
          Math.floor(i / columnSize) * (width + widthOffset) + width / 2
        const offsetY = (i % columnSize) * (height + heightOffset) + height / 2

        if (this.props.isMobile)
          return `translate(${offsetY} ${offsetX}) scale(0)`
        return `translate(${offsetX} ${offsetY}) scale(0)`
      })
      .transition()
      .ease(d3.easeElastic)
      .delay((_, i) => 50 * Math.floor(i / columnSize) + Math.random() * 200)
      .duration(200)
      .attr('transform', (_, i) => {
        const offsetX = Math.floor(i / columnSize) * (width + widthOffset)
        const offsetY = (i % columnSize) * (height + heightOffset)

        if (this.props.isMobile)
          return `translate(${offsetY} ${offsetX}) scale(1)`
        return `translate(${offsetX} ${offsetY}) scale(1)`
      })
  }
  render() {
    const { width, height } = getHexWidthHeight(hexSideLength)
    const screenWidth = ((width + widthOffset) * hexAmount) / columnSize
    const screenHeight = (height + heightOffset) * columnSize
    const [svgWidth, svgHeight] = this.props.isMobile
      ? [screenHeight, screenWidth]
      : [screenWidth, screenHeight]
    return (
      <Wrapper>
        <svg
          ref={element => (this.svg = element)}
          preserveAspectRatio="xMinYMin meet"
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        />
        <MediaQuery query={bpsUp.lg}>
          <div className="note" onClick={this.play}>
            {this.props.mode === 'normal'
              ? 'จำนวน ส.ส. คาดการณ์จริงในสภา \n ตัวเลขของแต่ละพรรครวม ส.ส. แบบแบ่งเขตและ ส.ส. แบบบัญชีรายชื่อแล้ว'
              : 'พรรคใดมีคะแนนเสียงถึงทุก 80,000 จะได้ ส.ส. รวมเพิ่ม 1 คน คำนวณบนสมมติฐานว่ามีบัตรดีที่ไม่รวม Vote No ทั้งสิ้น 40 ล้านใบ'}
          </div>
        </MediaQuery>
        <ReactTooltip
          id="overall-tooltip"
          getContent={partyCode => {
            if (!partyCode) return ''
            const votes = this.props.partyScores.find(
              p => p.partyCode === partyCode,
            )
            // const votes = 1231431
            const party = this.props.data.find(p => p.partyCode === partyCode)
            if (!votes) return ''
            party.votes = (votes || {}).votes
            if (!party.constituencyCandidates) party.constituencyCandidates = []
            if (!party.partylistCandidates) party.partylistCandidates = []
            return <PartyTooltip mode={this.props.mode} {...party} />
          }}
          effect="solid"
          type="light"
          place="right"
        />
      </Wrapper>
    )
  }
}
export default OverallPolygon
