import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'antd'
import { css } from 'emotion'
import { DateTime } from 'luxon'
import { HEADER_HEIGHT } from './const'

const blue = '#2590d2'
const orangered = '#FF4500'
const slategray = '#708090'
let backgroundColor = blue

const Container = styled.div`
  display: flex;
  background-color: ${backgroundColor};
  color: white;
  box-sizing: border-box;
  height: ${HEADER_HEIGHT};

  padding: 0.5rem;
  align-items: center;
`
const IconWrapper = styled.div`
  height: 30px;
  margin-right: 15px;
`
const StatusBox = ({ className, loading, error, data }) => {
  if (loading) {
    backgroundColor = orangered
  } else if (error) {
    backgroundColor = slategray
  } else {
    backgroundColor = blue
  }
  let percentage, lastUpdateAt
  if (data) {
    percentage = Math.floor(data.map.overview.percentage)
    const date = DateTime.fromJSDate(new Date(data.timestamp))
    lastUpdateAt = date.setLocale('th').toLocaleString(DateTime.DATE_MED)
  }
  return (
    <Container
      className={className}
      style={{ backgroundColor: backgroundColor }}
    >
      <IconWrapper>
        <Icon type="info-circle" style={{ fontSize: '30px' }} />
      </IconWrapper>
      <div>
        <div
          className={css`
            font-weight: 500;
            font-size: 1.25rem;
          `}
        >
          {percentage === 0
            ? 'ยังไม่มีข้อมูล'
            : `นับคะแนนไปแล้ว ${data &&
                data.map.overview.counted
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ใบ`}
        </div>
        <div
          className={css`
            font-size: 1rem;
            color: #9accea;
          `}
        >
          ข้อมูลอัปเดตเมื่อ {lastUpdateAt || '99 : 99 : 99'}
        </div>
      </div>
    </Container>
  )
}

export default StatusBox
