import React from 'react'
import styled from '@emotion/styled'
import { Icon } from 'antd'
import { css } from 'emotion'
import { DateTime } from 'luxon'
import CraftByCleverseSmall from './CraftByCleverseSmall'

const StyledCraftByCleverse = styled(CraftByCleverseSmall)`
  background: black;
  margin-left: 0px;
  padding-left: 0.75rem;
  padding-right: 1rem;
  margin-top: 0px;
  background: #56acd8;
  padding-top: 0.4rem;
  span {
    font-size: 0.75rem;
  }
  img {
    height: 1.2rem;
  }
`
const Container = styled.div`
  display: flex;
  background-color: #2590d2;
  color: white;
  box-sizing: border-box;

  align-items: center;
  padding-left: 1rem;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 1rem;
  width: auto;
  border-radius: 2rem;
  overflow: hidden;
`
const IconWrapper = styled.div`
  height: 30px;
  margin-right: 7px;
`
const StatusBoxMobile = ({ className, loading, error, data }) => {
  let percentage, lastUpdateAt
  if (data) {
    percentage = Math.floor(data.map.overview.percentage)
    const date = DateTime.fromJSDate(new Date(data.timestamp))
    lastUpdateAt = date.setLocale('th').toLocaleString(DateTime.DATE_MED)
  }
  return (
    <Container className={className}>
      <IconWrapper>
        <Icon type="info-circle" style={{ fontSize: '30px' }} />
      </IconWrapper>
      <div
        className={css`
          padding-right: 10px;
          height: 100%;
          overflow: auto;
        `}
      >
        <div
          className={css`
            font-weight: 600;
            font-size: 1.5rem;
          `}
        >
          {percentage === 0
            ? 'ยังไม่มีข้อมูล'
            : `นับคะแนนไปแล้ว ${data &&
                data.map.overview.counted
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ใบ`}
        </div>
        <div
          className={css`
            font-size: 1rem;
            color: #9accea;
          `}
        >
          ข้อมูลอัปเดตเมื่อ {lastUpdateAt || '99 : 99 : 99'}
        </div>
      </div>
      <StyledCraftByCleverse />
    </Container>
  )
}

export default StatusBoxMobile
