import React, { Component } from 'react'
import { getHexPointArray, getHexWidthHeight } from '../util/hexagonUtil.js'
import styled from '@emotion/styled'
// turn into a prop if needed
const hexSideLength = 15

const Wrapper = styled.div`
  position: relative;
  .inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 46%;
    text-align: center;
    font-size: 2rem;
    line-height: 1em;
    font-family:Tahoma;
    color: black;
    font-weight: bold;    
    transform: translate(0,-50%);
  }
`

class SinglePolygon extends Component {
  render() {
    const { width, height } = getHexWidthHeight(
      this.props.size || hexSideLength,
    )
    const hexPointArray = getHexPointArray(width, height)
    return (
      <Wrapper
        className={this.props.className}
        style={this.props.style}
        ref={element => (this.ref = element)}
      >
        <div className="inner">{this.props.children}</div>
        <svg
          preserveAspectRatio="xMinYMin meet"
          viewBox={`0 0 ${width} ${height}`}
          className="single-polygon-svg"
        >
          <polygon fill={this.props.color} points={hexPointArray} />
        </svg>
      </Wrapper>
    )
  }
}
export default SinglePolygon
