import React from 'react'
import { Router, Route } from 'react-router-dom'
import App from './App'
// import Map from './components/Map'
import { history } from './history'
// import OverallPolygon from './components/OverallPolygon'

export const routes = (
  <Router history={history}>
    <div>
      <Route path="/:modal?/:province?/:zone?" component={App} />
    </div>
  </Router>
)
