import * as d3 from 'd3'
import React, { Component } from 'react'

const barWidth = 10
const svgWidth = barWidth * 7
const svgHeight = 200

const prepareCandidateDataForGraph = (topCandidates, othersScore) => {
  let candidatesData = [...topCandidates]
  candidatesData.push({ partCode: 'empty', color: 'white', score: 0 })
  candidatesData.push({
    partCode: 'others',
    color: 'silver',
    score: othersScore,
  })
  return candidatesData
}

class ConstituencyGraph extends Component {
  componentDidMount() {
    this.updateData()
    this.play()
  }
  componentDidUpdate() {
    this.updateData()
    this.play()
  }

  updateData = () => {
    const topCandidates = this.props.topCandidates
    const othersScore = this.props.othersScore
    const candidatesData = prepareCandidateDataForGraph(
      topCandidates,
      othersScore,
    )
    const svg = d3.select(this.svg)

    const selection = svg
      .selectAll('rect')
      .data(candidatesData, c => c.partyCode)

    // enter
    selection.enter().append('rect')

    // exit
    selection.exit().remove()
  }
  play = () => {
    const topCandidates = this.props.topCandidates
    const othersScore = this.props.othersScore
    const topScore = Math.max(topCandidates[0].score, othersScore)
    const yScale = d3
      .scaleLinear()
      .domain([0, topScore])
      .range([200, 50])
    const heightScale = d3
      .scaleLinear()
      .domain([0, topScore])
      .range([0, 150])
    const svg = d3.select(this.svg)
    svg
      .selectAll('rect')
      .attr('x', (_, i) => i * barWidth)
      .attr('y', svgHeight)
      .attr('width', barWidth)
      .attr('height', 0)
      .attr('fill', d => d.color)
      .transition()
      .delay(100)
      .duration(1000)
      .ease(d3.easeExpInOut)
      .attr('y', d => yScale(d.score))
      .attr('height', d => heightScale(d.score))
  }
  render() {
    return (
      <svg
        ref={element => (this.svg = element)}
        preserveAspectRatio="none"
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        style={{ height: '100%', width: '100%' }}
      />
    )
  }
}

export default ConstituencyGraph
