export const bps = {
  xs: '(max-width: 575.98px)',
  sm: '(min-width: 576px) and (max-width: 767.98px)',
  md: '(min-width: 768px) and (max-width: 991.98px)',
  lg: '(min-width: 992px) and (max-width: 1199.98px)',
  xl: '(min-width: 992px) and (max-width: 1199.98px)',
}
export const bpsDown = {
  xs: '(max-width: 575.98px)',
  sm: '(max-width: 767.98px)',
  md: '(max-width: 899.98px)',
  lg: '(max-width: 1024px)',
  xl: '(max-width: 1279.98px)',
}
export const bpsUp = {
  xs: '(max-width: 576px)',
  sm: '(min-width: 768px)',
  md: '(min-width: 900px)',
  lg: '(min-width: 1025px)',
  xl: '(min-width: 12080px)',
}
