import styled from '@emotion/styled'
import { bpsDown } from '../styles/const'
export const HEADER_HEIGHT = '3rem'

export const MaxWidth = styled.div`
  width: 90%;
  margin-left: 2rem;
  margin-right: 2rem;
  @media ${bpsDown.lg} {
    margin: auto;
    max-width: 25rem;
  }
`

export const MaxWidthFull = styled.div`
  width: 95%;
  margin: auto;
  margin-left: 2rem;
  margin-right: 2rem;
`

export const MaxWidthLG = styled.div`
  width: 90%;
  margin-left: 2rem;
  margin-right: 2rem;
  @media ${bpsDown.lg} {
    margin: auto;
    max-width: 45rem;
  }
`

export const wheelingCheck = (e, context) => {
  if (!context.wheeling) {
    // console.log('start wheeling!');
  }
  if (!context.wheeldelta) {
    context.wheeldelta = {
      x: 0,
      y: 0,
    }
  }
  clearTimeout(context.wheeling)
  context.wheeling = setTimeout(function() {
    // console.log('stop wheeling!');
    context.wheeling = undefined

    context.wheeldelta.x = 0
    context.wheeldelta.y = 0
  }, 250)

  context.wheeldelta.x += e.deltaFactor * e.deltaX
  context.wheeldelta.y += e.deltaFactor * e.deltaY
}

export const wheelStartCheck = (e, context) => {
  let curTime = Date.now()
  // console.log('wheel');

  if (!context.scrollings) {
    context.scrollings = []
  }
  // cross-browser wheel delta
  e = e || window.event
  let value = e.wheelDelta || -e.deltaY || -e.detail

  //Limiting the array to 150 (lets not waste memory!)
  if (context.scrollings.length > 149) {
    context.scrollings.shift()
  }

  //keeping record of the previous context.scrollings
  context.scrollings.push(Math.abs(value))

  //time difference between the last scroll and the current one
  let timeDiff = curTime - context.prevTime
  context.prevTime = curTime

  //haven't they scrolled in a while?
  //(enough to be consider a different scrolling action to scroll another section)
  if (timeDiff > 200) {
    console.log(timeDiff)
    //emptying the array, we dont care about old context.scrollings for our averages
    context.scrollings = []
  }
  function getAverage(elements, number) {
    var sum = 0

    //taking `number` elements from the end to make the average, if there are not enought, 1
    var lastElements = elements.slice(Math.max(elements.length - number, 1))

    for (var i = 0; i < lastElements.length; i++) {
      sum = sum + lastElements[i]
    }

    return Math.ceil(sum / number)
  }

  let averageEnd = getAverage(context.scrollings, 10)
  let isAccelerating = false

  //COREEE
  if (
    !context.blockCal &&
    context.prevAvgEnd !== 0 &&
    averageEnd >= context.prevAvgEnd * 1.1
  ) {
    isAccelerating = true
    // console.log('sxc',context.prevAvgEnd,averageEnd)
    context.blockCal = true
    setTimeout(() => {
      context.blockCal = false
    }, 200)
  }
  context.prevAvgEnd = averageEnd

  return isAccelerating
}
