import React, { useState } from 'react'
import styled from '@emotion/styled'
import Entry from './Entry'
import { bpsDown, bpsUp } from '../../styles/const'
import Slider from 'react-slick'
import MediaQuery from 'react-responsive'
import { css } from 'emotion'

const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow: scroll;
  margin: auto;
  min-height: 30vh;
  @media ${bpsUp.down} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media ${bpsDown.sm} {
    width: 100%;
  }
  @media ${bpsDown.lg} {
    margin: auto 1rem;
    width: 100%;
    .hide-mobile {
      display: none;
    }
  }
`

const ShowAllButton = styled.button`
  display: none;
  @media ${bpsDown.lg} {
    padding: 10px 20px;
    border-radius: 10px;
    font-family: 'db_heavent';
    font-size: 24px;
    font-weight: 900;
    background: white;
    border: 0;
    display: block;
    margin: 10px auto 0;
  }
`

const PartyListResult = ({ loading, error, data }) => {
  const [showAll, setShowAll] = useState(false)
  if (!data) {
    return 'Loading...'
  }
  // if (error) {
  //   console.log('partylistresult error', error)
  //   return error
  // }
  const parties = data.partylist
  const partyItems = parties.map((party, i) => {
    if (party.seats > 0) {
      return (
        <Entry
          key={party.partyCode}
          data={party}
          className={i > 6 && !showAll ? 'hide-mobile' : 'show-mobile'}
        />
      )
    }
    return false
  })
  return (
    <Container
      onWheel={e => {
        e.stopPropagation()
      }}
      onTouchMove={e => {
        e.stopPropagation()
      }}
      className={css`
        @media ${bpsUp.lg} {
          width: 65vw;
          overflow: unset;
        }
      `}
    >
      <MediaQuery query={bpsDown.lg}>
        {partyItems}
        {!showAll && partyItems.length > 6 && (
          <ShowAllButton onClick={() => setShowAll(true)}>
            แสดงผลทั้งหมด
          </ShowAllButton>
        )}
      </MediaQuery>
      <MediaQuery query={bpsUp.lg}>
        <Slider
          vertical={true}
          rows={window.innerHeight > 800 ? 3 : 3}
          swipe={true}
          infinite={false}
          className={css`
            background-color: white;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            padding: 1rem;
            max-width: 60rem;
            .slick-prev {
              top: -50px;
            }
            .slick-next {
              bottom: -50px;
            }
          `}
        >
          {partyItems}
        </Slider>
      </MediaQuery>
    </Container>
  )
}

export default PartyListResult
