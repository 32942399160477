import * as d3 from 'd3'
import React, { Component } from 'react'
import { getHexWidthHeight, getHexPointArray } from '../../util/hexagonUtil.js'
import _ from 'lodash'
// turn into a prop if needed
const hexSideLength = 15
const columnSize = 10
const widthOffset = 4
const heightOffset = 4

class PartyListPolygon extends Component {
  componentDidMount() {
    this.update()
  }
  componentDidUpdate() {
    this.update()
  }
  update = () => {
    const { width, height } = getHexWidthHeight(hexSideLength)
    const hexPoints = getHexPointArray(width, height).join(' ')
    const svgWidth = 335
    const svgHeight =
      Math.ceil(this.props.number / columnSize) * (height + heightOffset) -
      heightOffset
    const data = _.range(this.props.number)
    const svg = d3
      .select(this.ref)
      .select('svg')
      .attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`)
      .attr('name-party', this.props.partyName)
    const hexes = svg.selectAll('polygon').data(data)
    hexes.exit().remove()
    hexes
      .enter()
      .append('polygon')
      .merge(hexes)
      .style('fill', this.props.color)
      .attr('points', hexPoints)
      .attr('transform', (d, i) => {
        const offsetY = Math.floor(d / columnSize) * (width + widthOffset)
        const offsetX = (d % columnSize) * (height + heightOffset)
        return `translate(${offsetX} ${offsetY}) scale(1)`
      })
  }

  render() {
    return (
      <div
        className="partylist-svg-container"
        ref={element => (this.ref = element)}
      >
        <svg
          style={{ width: '100%' }}
          id="partylist-svg"
          preserveAspectRatio="xMinYMin meet"
        />
      </div>
    )
  }
}
export default PartyListPolygon
