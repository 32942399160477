import styled from '@emotion/styled'
import { message } from 'antd'
import React from 'react'
import { css } from 'emotion'
import workpointNewsImg from './assets/workpoint_logo_long_black.png'
import electionLogo from './assets/election-logo.png'
import { DateTime } from 'luxon'
import ConstituencyGraph from './ConstituencyGraph.js'
import close from '../components/assets/close.svg'
import crossImg from './assets/cross.png'
import polygonImg from './assets/hex-white.png'
import placeHolder from './assets/placeholder.png'
import { bpsDown } from '../styles/const.js'
import SinglePolygon from '../components/SinglePolygon'

const calculateOthersData = (candidates, totalScore) => {
  let percentage = 100
  let score = totalScore
  candidates.forEach(candidate => {
    percentage -= Math.floor(candidate.percentage)
    score -= candidate.score
  })
  return { othersPercentage: percentage, othersScore: score }
}

const ImgContainer = styled.div`
  max-width: 3rem;
  padding: 0.5rem;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
`

const ModalBody = ({ provinces, params, closeModal, timestamp }) => {
  if (!params.province) {
    message.error({
      message: 'Missing Province Code',
    })
    return 'Missing Province Code'
  }
  const provinceCode = params.province
  const province = provinces.find(p => p.provinceCode === provinceCode)
  const provinceName = province ? province.provinceName : 'จังหวัด'
  if (!province) {
    message.error({
      message: 'Invalid Province Code',
    })
    return 'Invalid Province Code'
  }
  const zoneNo = params.zone || 0
  const zone = province.zones.find(z => z.zoneNo === parseInt(zoneNo)) || {}

  if (!zone.zoneDesc) {
    message.error({
      message: 'Invalid Zone',
    })
    return 'Invalid zone'
  }
  const candidates = zone.topCandidates
  const { othersPercentage, othersScore } = calculateOthersData(
    candidates,
    zone.totalScore,
  )
  const date = DateTime.fromJSDate(new Date(timestamp))
  const lastUpdateAt = date.setLocale('th').toLocaleString(DateTime.DATE_MED)
  const firstCandidate = candidates[0] || {}
  return (
    <div
      onWheel={e => {
        e.stopPropagation()
      }}
      className={css`
        flex: 1 1 0;
        height: 100%;
        overflow: auto;
        position: relative;
        width: 100%;
        display: flex;
        padding: 2rem;
        box-sizing: border-box;
        @media ${bpsDown.sm} {
          padding: 0;
        }
      `}
    >
      <div
        className={css`
          background-color: #f4f6f6;
          display: flex;
          flex: 1;
          flex-direction: column;
          max-width: 70rem;
          margin: auto;
          border-radius: 1rem;
          @media ${bpsDown.sm} {
            border-radius: 0rem;
          }
          position: relative;
          overflow: visible;
          box-sizing: border-box;
          height: 100%;
        `}
      >
        <div
          className={css`
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 2rem;
            padding-bottom: 0;
            padding-top: 0;
            overflow: auto;
            border-radius: 1rem;
            @media ${bpsDown.sm} {
              padding: 0;
            }
          `}
        >
          <div
            className={css`
              position: fixed;
              font-size: 800px;
              opacity: 0.05;
            `}
          >
            <div
              className={css`
                font-size: 48rem;
                pointer-events: none;
                background: linear-gradient(280deg, #000000 30%, #f8f8f8 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                margin: auto;
              `}
            >
              {zoneNo}
            </div>
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 1rem;

              @media ${bpsDown.md} {
                justify-content: center;
              }

              @media ${bpsDown.sm} {
                justify-content: space-between;
                flex-direction: column;
                align-items: start;
              }
            `}
          >
            <div
              className={css`
                display: inline-flex;
                margin-left: 1rem;
                align-items: center;
              `}
            >
              <img
                className={css`
                  width: auto;
                  height: 1.5rem;
                `}
                src={workpointNewsImg}
                alt="-Workpoint News"
              />
              <img
                className={css`
                  width: auto;
                  height: 1.5rem;
                  padding-left: 1rem;
                `}
                src={electionLogo}
                alt="Election 2019"
              />
            </div>
            {zone.overidden && (
              <p
                className={css`
                  text-align: right;
                  @media ${bpsDown.sm} {
                    flex-direction: column;
                    width: 100%;
                    text-align: center;
                    margin-top: 1.5rem;
                  }
                `}
              >
                อ้างอิงผลจากประกาศรายชื่อผู้ชนะ ส.ส. เขต 350 คน ของ กกต.
                <br />
                ตัวเลขคะแนนดิบที่เห็นมาจากฐานข้อมูลที่ กกต. ส่งให้สื่อ
                ซึ่งนับคะแนนเสร็จเพียง 94%
              </p>
            )}
          </div>
          <div
            className={css`
              display: flex;
              /* background-color: #f2f1f0; */
              flex: 1;
              max-width: 80rem;
              margin: auto;
              width: 100%;

              @media ${bpsDown.xl} {
                flex-wrap: wrap;
                justify-content: center;
              }
            `}
          >
            <div
              className={css`
                width: 33%;
                display: flex;
                align-items: center;

                @media ${bpsDown.xl} {
                  flex: 1;
                }
                @media ${bpsDown.md} {
                  width: 100%;
                  flex: unset;
                }

                @media ${bpsDown.md} {
                  margin-top: 3rem;
                  text-align: center;
                  margin-bottom: 2rem;
                }
              `}
            >
              <div
                className={css`
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  align-items: center;
                  font-weight: 600;

                  @media ${bpsDown.md} {
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: center;
                  }
                `}
              >
                <div
                  className={css`
                    opacity: 0.6;
                    font-size: 1.266rem;
                    word-break: break-word;
                    line-height: 1.5rem;
                    @media ${bpsDown.md} {
                      font-size: 2.5rem;
                      margin-right: 0.5rem;
                      opacity: 1;
                    }
                  `}
                >
                  {provinceName}
                </div>
                <div>
                  <h4
                    className={css`
                      @media ${bpsDown.md} {
                        font-size: 3rem;
                      }

                      font-size: 7rem;
                      margin: 0;
                    `}
                  >
                    {zoneNo}
                  </h4>
                </div>
                <div
                  className={css`
                    @media ${bpsDown.md} {
                      width: 100%;
                    }
                  `}
                >
                  <p
                    className={css`
                      @media ${bpsDown.md} {
                        margin: auto;
                      }

                      display: flex;
                      flex-direction: column;
                      text-align: center;
                      opacity: 0.6;
                      font-size: 1.266rem;
                      line-height: 1.5rem;
                      margin: 0 auto;
                      word-break: break-word;
                      max-width: 16rem;
                    `}
                  >
                    {zone.zoneDesc
                      ? zone.zoneDesc.map((name, i) => {
                          return (
                            <span
                              className={css`
                                margin-bottom: 0.5rem;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                              `}
                              key={i}
                            >
                              {name.split(' ').map(word => (
                                <span
                                  className={css`
                                    white-space: nowrap;
                                  `}
                                  key={word}
                                >
                                  {word}&nbsp;
                                </span>
                              ))}
                            </span>
                          )
                        })
                      : 'ชื่อเขต'}
                  </p>
                  <p
                    className={css`
                      font-weight: 500;
                      color: #999;
                      margin-top: 10px;
                      text-align: center;
                      font-size: 3vh;
                      @media ${bpsDown.sm} {
                        font-size: 23px;
                      }
                    `}
                  >
                    นับคะแนนไปแล้วทั้งสิ้น{' '}
                    {zone.totalVotes &&
                      zone.totalVotes
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                    ใบ
                    <br />
                    อัปเดตเมื่อ {lastUpdateAt || '??:??:??'}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={css`
                flex: 1;
                display: flex;
                @media ${bpsDown.xl} {
                  flex-wrap: wrap;
                  max-width: 28rem;
                  height: 100%;
                  overflow: auto;
                  padding-left: 3rem;
                  box-sizing: border-box;

                  > * {
                    width: 100% !important;
                  }

                  left: -1.5rem;
                  position: relative;
                }

                @media ${bpsDown.md} {
                  left: 0;
                  height: auto;

                  padding-left: 1rem;
                  padding-right: 1rem;
                }
              `}
            >
              <div
                flex={1}
                className={css`
                  display: flex;
                  flex-direction: column;
                  width: 50%;
                  min-width: 20rem;
                  z-index: 9;
                  @media ${bpsDown.sm} {
                    width: 50%;
                    padding: 2rem;
                    box-sizing: border-box;
                  }
                `}
              >
                <div
                  className={css`
                    margin: 10px;
                    /* background-color: #82e0aa; */
                  `}
                >
                  <div
                    className={css`
                      background-color: white;
                      padding: 0.5rem;
                      border-radius: 1rem;
                    `}
                  >
                    <div
                      className={css`
                        display: flex;
                        width: 100%;
                      `}
                    >
                      <ImgContainer
                        className={css`
                          max-width: 12rem;
                          height: auto;
                          transform: translate3d(-2.5rem, 0, 0);
                          @media ${bpsDown.lg} {
                            max-width: 9rem;
                          }
                        `}
                      >
                        <div
                          className={css`
                            max-width: 4rem;
                            position: absolute;
                            transform: translate3d(-2rem, 2rem, 0);
                            @media ${bpsDown.lg} {
                              max-width: 3rem;
                              transform: translate3d(-1.5rem, 1.5rem, 0);
                            }
                          `}
                        >
                          <h4
                            className={css`
                              margin: auto;
                              width: 100%;
                              height: 100%;
                              text-align: center;
                              line-height: 4.5rem;
                              font-size: 4.11rem;
                              position: absolute;
                              @media ${bpsDown.lg} {
                                font-size: 3.247rem;
                                line-height: 3.5rem;
                              }
                            `}
                          >
                            1
                          </h4>
                          <img alt="polygon" src={polygonImg} />
                        </div>
                        <img
                          className={css`
                            border-radius: 1rem;
                            box-shadow: 1rem 1rem 2rem 0 rgba(88, 88, 88, 0.33),
                              0 6px 20px 0 rgba(0, 0, 0, 0);
                          `}
                          alt={firstCandidate.candidate}
                          src={firstCandidate.picture}
                        />
                      </ImgContainer>
                      <ImgContainer
                        className={css`
                          margin: auto;
                          max-width: 8rem;
                        `}
                      >
                        <img
                          alt={firstCandidate.partyName}
                          src={firstCandidate.partyPic}
                        />
                      </ImgContainer>
                    </div>
                    <div
                      className={css`
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                      `}
                    >
                      <div
                        className={css`
                          padding-left: 1rem;
                        `}
                      >
                        <h4
                          className={css`
                            margin: 0;
                            display: flex;
                            flex-direction: column;
                            font-weight: 400;
                            opacity: 0.6;
                          `}
                        >
                          พรรค{firstCandidate.partyName}
                        </h4>
                        <h4
                          className={css`
                            margin: 0;
                            font-size: 1.3rem;
                            line-height: 1.5rem;
                          `}
                        >
                          {firstCandidate.candidate}
                        </h4>
                      </div>
                      <div
                        className={css`
                          display: flex;
                          padding-right: 1rem;
                        `}
                      >
                        <ImgContainer
                          className={css`
                            max-width: 2rem;
                          `}
                        >
                          <img alt="cross" src={crossImg} />
                        </ImgContainer>
                        <div
                          className={css`
                            display: flex;
                            flex-direction: column;
                          `}
                        >
                          <h4
                            className={css`
                              margin: 0rem;
                              font-size: 1.424rem;
                              line-height: 1.5rem;
                              font-weight: 500;
                            `}
                          >
                            {firstCandidate.score
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </h4>
                          <h4
                            className={css`
                              margin: 0rem;
                              font-size: 1.424rem;
                              line-height: 1.5rem;
                              font-weight: 500;
                              opacity: 0.6;
                            `}
                          >
                            {Math.floor(firstCandidate.percentage)}%
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={css`
                    margin: 10px;
                  `}
                >
                  {candidates.map((candidate, i) => {
                    if (i === 0) return null
                    return (
                      <div
                        key={candidate.partyCode}
                        className={css`
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                        `}
                      >
                        <div
                          className={css`
                            width: 5%;
                          `}
                        >
                          <h4>{i + 1}</h4>
                        </div>
                        <ImgContainer
                          className={css`
                            width: 10%;
                          `}
                        >
                          <img
                            alt={candidate.partyName}
                            src={candidate.partyPic}
                          />
                        </ImgContainer>
                        <ImgContainer
                          className={css`
                            width: 10%;
                          `}
                        >
                          <img
                            alt={candidate.candidate}
                            src={candidate.picture}
                            className={css`
                              border-radius: 50%;
                            `}
                          />
                        </ImgContainer>
                        <div
                          className={css`
                            display: flex;
                            flex-direction: column;
                            width: 55%;
                            font-weight: 500;
                            font-size: 1.125rem;
                            line-height: 1rem;
                          `}
                        >
                          <div
                            className={css`
                              opacity: 0.6;
                            `}
                          >
                            {candidate.partyName}
                          </div>
                          <div>{candidate.candidate}</div>
                        </div>
                        <div
                          className={css`
                            display: flex;
                            flex-direction: column;
                            text-align: right;
                          `}
                        >
                          <div
                            className={css`
                              width: 20%;
                            `}
                          >
                            <h4
                              className={css`
                                margin: 0;
                                font-size: 1.424rem;
                                line-height: 1.5rem;
                                font-weight: 500;
                              `}
                            >
                              {candidate.score
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </h4>
                          </div>
                          <div
                            className={css`
                              opacity: 0.6;
                              font-weight: 500;
                              font-size: 1.125rem;
                            `}
                          >
                            {Math.floor(candidate.percentage)}%
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div
                className={css`
                  width: 50%;
                  padding-left: 2rem;
                  padding-top: 1rem;

                  margin-bottom: 2rem;

                  @media ${bpsDown.xl} {
                    padding-left: 0rem;
                  }
                `}
              >
                <div
                  className={css`
                    background-color: white;
                    width: 100%;
                    min-height: 100%;
                    display: flex;
                    border-radius: 1rem;
                    flex-wrap: wrap;
                    height: auto;
                  `}
                >
                  <div
                    className={css`
                      flex: 1;
                      height: auto;
                      flex-direction: column;
                      display: block;
                      padding: 0.6rem 0.4rem;
                    `}
                  >
                    {candidates.map(candidate => (
                      <div
                        key={candidate.partyCode}
                        className={css`
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          margin: 0.4rem 0;
                          position: relative;
                        `}
                      >
                        <ImgContainer
                          className={css`
                            padding-bottom: 0rem;
                          `}
                        >
                          <img
                            alt={'wt' + candidate.partyName}
                            src={candidate.partyPic}
                          />
                        </ImgContainer>
                        <SinglePolygon
                          style={{
                            width: '16px',
                            height: '20px',
                          }}
                          className={css`
                            min-height: 100%;
                            position: absolute;
                            bottom: 0rem;
                            right: 0rem;
                            top: auto;
                            height: auto;
                            bottom: 1rem;
                            min-height: auto;
                          `}
                          color={candidate.color}
                        />
                        <div>
                          <h4>{Math.floor(candidate.percentage)} %</h4>
                        </div>
                      </div>
                    ))}

                    <div
                      className={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      `}
                    >
                      <ImgContainer
                        className={css`
                          padding-bottom: 0rem;
                        `}
                      >
                        <img src={placeHolder} alt="อื่นๆ" />
                      </ImgContainer>
                      <div>
                        <h4 className={css``}>{othersPercentage} %</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className={css`
                      flex: 5;
                      background-color: white;
                      position: relative;
                      border-top-right-radius: 1rem;
                      border-bottom-right-radius: 1rem;
                    `}
                  >
                    <div
                      className={css`
                        height: 100%;
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                      `}
                    >
                      <ConstituencyGraph
                        topCandidates={zone.topCandidates}
                        othersScore={othersScore}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={closeModal}
          className={css`
            display: inline-block;
            align-items: center;
            padding: 1rem;
            background-color: #fff;
            z-index: 99999;
            cursor: pointer;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 0rem;
            right: 0rem;

            box-shadow: -1rem 0rem 2rem 0 rgba(88, 88, 88, 0.33),
              0 6px 20px 0 rgba(0, 0, 0, 0);
            font-size: 2.887rem;
            transform: translate(50%, -50%);

            @media ${bpsDown.sm} {
              transform: translate(0%, 0%);
              top: 1rem;
              right: 1rem;
            }
          `}
        >
          <img
            alt="close button"
            src={close}
            className={css`
              width: 63%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              position: absolute;
            `}
          />
        </div>
      </div>
    </div>
  )
}
export default ModalBody
