import React from 'react'
import styled from '@emotion/styled'
import { css } from 'emotion'
import workpointNewsImg from './assets/workpoint_logo_long_black.png'
import workpointNewsImgWhite from './assets/workpoint_logo_long_white.png'
import electionLogo from './assets/election-logo.png'
import electionLogoWhite from './assets/election-logo-white.png'
import { bpsDown,bpsUp } from '../styles/const'
import { MaxWidthFull } from '../components/const'

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  @media ${bpsDown.lg} {
    
    justify-content: space-between;
    width: 100%;
  }
`

const Menu = props => {
  return (

    <MaxWidthFull className={css`
        @media ${bpsUp.md} {
            max-width: 33%;
        }
    `}>
      <Container>
        <span
          className={css`
            position: relative;
            height: 1.5rem;
          `}
        >
          <img
            className={css`
              height: 100%;
              width: auto;
            `}
            data-class={'wp-black'}
            src={workpointNewsImg}
            alt="Workpoint News"
          />
          <img
            data-class={'wp-white'}
            className={css`
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
            `}
            src={workpointNewsImgWhite}
            alt="Workpoint News"
          />
        </span>
        <span
          className={css`
            position: relative;
            height: 1.5rem;
          `}
        >
          <img
            data-class={'elect-black'}
            className={css`
              width: auto;
              max-height: 1.5rem;
              padding-left: 1rem;
            `}
            src={electionLogo}
            alt="Election 2019"
          />
          <img
            data-class={'elect-white'}
            className={css`
              width: auto;
              max-height: 1.5rem;
              padding-left: 1rem;
              position: absolute;
              top: 0;
              left: 0;
            `}
            src={electionLogoWhite}
            alt="Election 2019"
          />
        </span>
      </Container>
    </MaxWidthFull>
  )
}

export default Menu
