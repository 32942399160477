import { history } from './history'

export const getPathname = () => history.location.pathname

export const pushRoute = pathname => {
  history.push({
    pathname,
    hash: history.location.hash,
  })
}

export default pushRoute
