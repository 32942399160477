import React from 'react'
import OverallSideberContainer from '../components/OverallSidebarContainer'
import OverallPolygon from '../components/OverallPolygon'
import Template from '../components/Template'
import styled from '@emotion/styled'
import { css } from 'emotion'
import MediaQuery from 'react-responsive'
import { bpsDown, bpsUp } from '../styles/const'
import HeadlineSm from '../components/HeadlineSm'

const Headline = ({ className }) => (
  <div className={className}>
    <div className="title">คาดการณ์ผลเลือกตั้ง ส.ส. รวมทั้งหมด</div>
    <div className="number">500 ที่นั่ง</div>
  </div>
)

const StyledHeadline = styled(Headline)`
  text-align: center;
  font-weight: 600;
  padding-left: 5rem;
  margin-bottom: 2rem;
  .title {
    font-size: 48px;
    color: #7d7d7d;
  }
  .number {
    font-size: 115px;
  }
`
const OverallPolygonWrapper = styled.div`
  text-align: center;
`
const OverallSideberContainerWrapper = styled.div`
  display: flex;
  height: 100%;
  @media ${bpsDown.lg} {
  }
`
const TemplateItemMobile = styled(Template.Item)`
  display: flex;
  flex-direction: row;
`
const OverallPage = ({ loading, error, data, isFocus }) => {
  if (!data) return 'Loading...'
  return (
    <Template.Container
      className={css`
        @media ${bpsDown.lg} {
        }
      `}
    >
      <MediaQuery query={bpsDown.lg}>
        <Template.Left>
          <HeadlineSm title="รวมทั้งหมด" prefix="คาดการณ์" number={500} />
        </Template.Left>
        <TemplateItemMobile>
          <div
            className={css`
              flex: 1 0 30vw;
              margin-right: 3vw;
              align-self: center;
            `}
          >
            <div className={css``}>
              <OverallPolygon
                mode={data.partylistHidden ? 'pre70' : 'normal'}
                data={data.partylistHidden ? data.pre70Overall : data.overall}
                partyScores={data.map.overview.ranking}
                isFocus={isFocus}
                isMobile={true}
              />
            </div>
          </div>
          <OverallSideberContainerWrapper>
            <OverallSideberContainer
              data={data.partylistHidden ? data.pre70Overall : data.overall}
              partylistHidden={data.partylistHidden}
            />
          </OverallSideberContainerWrapper>
        </TemplateItemMobile>
      </MediaQuery>
      <MediaQuery query={bpsUp.lg}>
        <Template.Item
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
          `}
        >
          <StyledHeadline />
          <OverallPolygonWrapper
            className={css`
              padding-left: 5rem;
            `}
          >
            <OverallPolygon
              mode={data.partylistHidden ? 'pre70' : 'normal'}
              data={data.partylistHidden ? data.pre70Overall : data.overall}
              partyScores={data.map.overview.ranking}
              isFocus={isFocus}
            />
          </OverallPolygonWrapper>
        </Template.Item>
        <Template.Right>
          <OverallSideberContainerWrapper>
            <OverallSideberContainer
              data={data.partylistHidden ? data.pre70Overall : data.overall}
              partylistHidden={data.partylistHidden}
            />
          </OverallSideberContainerWrapper>
        </Template.Right>
      </MediaQuery>
    </Template.Container>
  )
}
export default OverallPage
