import React from 'react'
import { css } from 'emotion'
import close from '../components/assets/close.svg'
import SinglePolygon from './SinglePolygon.js'
import { bpsDown } from '../styles/const'

const Card = ({ data: { i, length, picture, title, description } }) => (
  <div
    key={title}
    className={css`
      ${i === 0 ? 'border-radius: 1rem 1rem 0 0' : ''};
      ${i === length - 1 ? 'border-radius: 0 0 1rem 1rem' : ''};
      @media ${bpsDown.lg} {
        width: 100%;
        max-width: 30rem;
        box-sizing: border-box;
      }
      padding: 1rem;
      background: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    `}
  >
    <div
      className={css`
        display: flex;
        align-items: center;
        @media ${bpsDown.lg} {
          width: 100%;
          max-width: 30rem;
          box-sizing: border-box;
        }
      `}
    >
      <div
        className={css`
          width: 2.5rem;
          text-align: center;

          margin-right: 1rem;

          font-size: 3rem;
          white-space: nowrap;
          font-weight: 500;
        `}
      >
        {i + 1}
      </div>
      <div
        className={css`
          line-height: 0;
          margin-right: 1rem;
        `}
      >
        <img
          alt={title}
          src={picture}
          className={css`
            width: 4rem;
            height: 4rem;
            border-radius: 18px;
            margin-right: 8px;
          `}
        />
      </div>
      <div
        className={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <span
          className={css`
            font-weight: 500;
          `}
        >
          {title}
        </span>
        {description ? <span>{description}</span> : false}
      </div>
    </div>
  </div>
)

const PartyDetail = ({
  party,
  isMobile,
  provinces,
  partylistHidden,
  rankingItem,
}) => {
  return (
    <div
      className={css`
        display: flex;
        ${isMobile ? 'flex-direction: column;' : 'flex-direction: row;'}
        font-size: 1.5rem;
        height: 100%;
        overflow: auto;
        @media ${bpsDown.xl} {
          flex-wrap: wrap;
          flex-direction: row;
        }
      `}
    >
      <div
        className={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          max-width: 18rem;

          @media ${bpsDown.xl} {
            width: 100%;
            max-width: none;
            flex: auto;
            flex-direction: row;
            justify-content: space-between;
            background: white;
          }
          @media ${bpsDown.md} {
            padding-bottom: 1rem;
            padding-top: 1rem;
          }
          @media ${bpsDown.sm} {
            padding-bottom: 1rem;
            padding-top: 1rem;
          }
          @media ${bpsDown.sm} {
            padding-bottom: 1rem;
            padding-top: 1rem;
          }

          @media ${bpsDown.xs} {
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-start;
          }
        `}
      >
        <div
          className={css`
            background: white;
            text-align: center;

            @media ${bpsDown.xl} {
              display: flex;
              align-items: center;
            }
            @media ${bpsDown.xs} {
              flex-direction: column;
              margin-top: 1rem;
            }
          `}
        >
          <img
            alt={party.partyName}
            src={party.picture}
            className={css`
              display: block;
              margin-left: auto;
              margin-right: auto;
              max-width: 9rem;
              width: 100%;
              margin-bottom: 1rem;
              margin-top: 3rem;
              @media ${bpsDown.xs} {
                max-width: 6rem;
              }

              @media ${bpsDown.xl} {
                margin-bottom: 0rem;
                margin-top: 0rem;
                margin-left: 2rem;
                margin-right: 1.5rem;
                max-width: 7rem;
              }
            `}
          />
          <div
            className={css`
              font-size: 2.25rem;
              margin-bottom: 1rem;
              font-weight: 500;
              color: ${party.color};
              @media ${bpsDown.xl} {
                font-size: 3rem;
                margin-bottom: 0rem;
              }
              @media ${bpsDown.xs} {
                margin-top: 0.5rem;
                margin-bottom: 2rem;
              }
            `}
          >
            {party.partyName}
          </div>
        </div>
        <div
          className={css`
            @media ${bpsDown.xl} {
              flex: 0;
              width: auto;
              padding-top: 1rem;
              padding-bottom: 0rem;
              margin-right: 1rem;
              flex-direction: row-reverse;
            }
            @media ${bpsDown.xs} {
              display: block;
              padding-top: 0rem;
              padding-bottom: 1rem;
              margin-right: 0;
            }

            flex: 1;
            display: flex;
            background: white;
            position: relative;
            flex-direction: column;
            justify-content: start;
            align-items: center;
          `}
        >
          <div
            className={css`
              @media ${bpsDown.xs} {
                margin: auto;
                margin-bottom: 1rem;
              }
              @media ${bpsDown.xl} {
                width: 7rem;
                height: 8.2rem;
                margin-top: 0rem;
              }

              width: 10rem;
              height: 11.7rem;
              margin-top: 0rem;
            `}
          >
            <SinglePolygon color={party.color}>
              <div
                className={css`
                  font-family: 'db_heavent';
                  color: white;
                  text-shadow: 0px 0px 30px #00000075;
                `}
              >
                <div
                  className={css`
                    font-size: 4rem;
                    line-height: 3rem;
                  `}
                >
                  {partylistHidden ? party.constituencySeats : party.seats}
                </div>
                <div
                  className={css`
                    font-size: 2rem;
                    line-height: 2rem;
                    font-weight: 400;
                  `}
                >
                  ที่นั่ง
                </div>
              </div>
            </SinglePolygon>
          </div>
          <div
            className={css`
              text-align: center;
              font-size: 2rem;
              line-height: 2rem;
              font-weight: 400;
              margin-top: 20px;
            `}
          >
            คะแนนรวม{' '}
            <strong>
              {(rankingItem
                ? rankingItem.votes
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : '?') || '??'}
            </strong>{' '}
            เสียง
          </div>
          <div
            className={css`
              font-size: 1.1rem;
              text-align: center;
              margin-top: 20px;
              @media ${bpsDown.xl} {
                white-space: nowrap;
                text-align: right;
                margin-right: 1.5rem;
                font-size: 1.35rem;
              }

              @media ${bpsDown.md} {
                white-space: nowrap;
                text-align: right;
                margin-right: -1rem;
                font-size: 1.35rem;
                position: absolute;
                top: 100%;
                right: 0;
                background: white;
                padding-right: 1.5rem;
                padding-left: 1.5rem;
              }

              @media ${bpsDown.xs} {
                position: relative;
                top: 0;
                text-align: center;
              }
            `}
          >
            หมายเหตุ : ตัวเลข ส.ส. อาจเพิ่มหรือลดได้ <br />
            ระหว่างที่การนับคะแนนยังไม่เสร็จสิ้น
          </div>
        </div>
      </div>
      {!partylistHidden && (
        <div
          className={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: auto;
            padding: 2rem;

            @media ${bpsDown.lg} {
              width: 100%;
              flex: none;
              padding-left: 5%;
              padding-right: 5%;
              align-items: center;
              box-sizing: border-box;
              border-right: 0px solid rgba(0, 0, 0, 0.1);
            }
          `}
        >
          <div
            className={css`
              font-size: 2.25rem;
              margin-bottom: 2rem;
              line-height: 2.5rem;

              @media ${bpsDown.lg} {
                margin: auto;
                width: 100%;
                max-width: 30rem;
                padding-top: 2rem;
                padding-bottom: 1rem;
              }
            `}
          >
            ส.ส. บัญชีรายชื่อ ({party.partylistCandidates.length})
          </div>
          {party.partylistCandidates.map((candidate, i) => {
            return (
              <Card
                key={candidate.candidate}
                data={{
                  i: i,
                  length: party.partylistCandidates.length,
                  picture: candidate.picture,
                  title: candidate.candidate,
                }}
              />
            )
          })}
        </div>
      )}
      <div
        className={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow: auto;
          padding: 2rem;
          border-right: 1px solid rgba(0, 0, 0, 0.1);

          @media ${bpsDown.lg} {
            width: 100%;
            flex: none;
            padding-left: 5%;
            padding-right: 5%;
            align-items: center;

            box-sizing: border-box;
            border-right: 0px solid rgba(0, 0, 0, 0.1);
          }
          @media ${bpsDown.md} {
            border-right: 0px solid rgba(0, 0, 0, 0);
          }
        `}
      >
        <div
          className={css`
            font-size: 2.25rem;
            margin-bottom: 2rem;
            line-height: 2.5rem;

            @media ${bpsDown.lg} {
              margin: auto;
              width: 100%;
              max-width: 30rem;
              padding-top: 2rem;
              padding-bottom: 1rem;
            }
          `}
        >
          ส.ส. เขต ({party.constituencyCandidates.length})
        </div>
        {party.constituencyCandidates.map((candidate, i) => {
          const match = candidate.zone.match(/(.+):(.+)/)
          const provinceCode = match[1]
          const zoneNo = match[2]
          const province = provinces.find(p => p.provinceCode === provinceCode)
          return (
            <Card
              key={candidate.candidate}
              data={{
                i: i,
                length: party.constituencyCandidates.length,
                picture: candidate.picture,
                title: candidate.candidate,
                description: `${province.provinceName} เขต ${zoneNo}`,
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

const PartyDetailModal = ({
  data,
  params,
  closeModal,
  isMobile,
  provinces,
  partylistHidden,
  ranking,
}) => {
  let msg = ''
  let error = false
  if (!params.province) {
    error = true
    msg = 'Missing Party Code'
  }
  const overall = data.overall
  const partyCode = params.province
  const rankingItem = ranking.find(p => p.partyCode === partyCode)
  const party = overall.find(p => p.partyCode === partyCode)
  if (!party) {
    error = true
    msg = 'Invalid Party Code'
  }
  return (
    <div
      onWheel={e => {
        e.stopPropagation()
      }}
      className={css`
        position: relative;
        width: 100%;
        flex: 1;
        display: flex;
        padding: 2rem;
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;

        @media ${bpsDown.sm} {
          padding: 0;
        }
      `}
    >
      <div
        className={css`
          background-color: #f4f6f6;
          display: flex;
          flex: 1;
          flex-direction: column;

          max-width: 88rem;
          margin: auto;
          border-radius: 1rem;
          @media ${bpsDown.sm} {
            border-radius: 0rem;
          }

          position: relative;
          overflow: visible;
          box-sizing: border-box;
          height: 100%;
        `}
      >
        <div
          onClick={closeModal}
          className={css`
            display: inline-block;
            align-items: center;
            padding: 1rem;
            background-color: #fff;
            cursor: pointer;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 0rem;
            right: 0rem;
            z-index: 999;

            box-shadow: -1rem 0rem 2rem 0 rgba(88, 88, 88, 0.33),
              0 6px 20px 0 rgba(0, 0, 0, 0);
            font-size: 2.887rem;
            transform: translate(50%, -50%);

            @media ${bpsDown.sm} {
              transform: translate(0%, 0%);
              top: 1rem;
              right: 1rem;
            }
          `}
        >
          <img
            alt="close button"
            src={close}
            className={css`
              width: 63%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              position: absolute;
            `}
          />
        </div>
        <div
          className={css`
            display: flex;
            flex: 1;
            flex-direction: column;

            overflow: auto;
            border-radius: 1rem;
          `}
        >
          {error ? (
            msg
          ) : (
            <PartyDetail
              party={party}
              isMobile={isMobile}
              provinces={provinces}
              partylistHidden={partylistHidden}
              rankingItem={rankingItem}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default PartyDetailModal
