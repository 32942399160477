import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from 'emotion'
import { bpsDown } from '../../styles/const'
import { pushRoute } from '../../routing.js'

const ShowAllButton = styled.button`
  display: none;
  @media ${bpsDown.lg} {
    padding: 10px 20px;
    border-radius: 10px;
    font-family: 'db_heavent';
    font-size: 24px;
    font-weight: 900;
    background: white;
    border: 0;
    display: block;
    margin: 10px auto 0;
  }
`

const CardContent = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4%;
  i {
    margin: auto;
    display: inline-flex;
    color: #ccc;
    width: 10%;
  }
  .rank {
    color: #000000;
    font-size: 1rem;
    opacity: 0.65;

    margin: 0;
  }
`

const ImgContainer = styled.div`
  max-width: 5rem;
  margin-right: 4%;
  width: 30%;
  flex: 1 0 auto;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
`

const CardElectionMobile = ({ ranking, className, overall, showAll }) => {
  let currentRank = 0
  let currentSeat = 0
  return (
    <div
      onWheel={e => {
        e.stopPropagation()
      }}
      onTouchMove={e => {
        e.stopPropagation()
      }}
      className={`${className} ${css`
        padding-left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media ${bpsDown.md} {
          flex-direction: column;
          overflow: auto;
          display: flex;
        }
      `}`}
    >
      {(overall || []).map((r, i) => {
        let rank = i + 1
        if (currentSeat === r.seats) {
          rank = currentRank
        }
        currentSeat = r.seats
        currentRank = rank
        return (
          <CardMemberMapElectionMobile
            key={r.partyName}
            {...r}
            rank={rank}
            show={i > 3 && !showAll ? false : true}
          />
        )
      })}
    </div>
  )
}

export class CardMemberMapElectionMobile extends Component {
  render() {
    const { show } = this.props
    if (!show || this.props.seats < 1) return null
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        onClick={() => pushRoute('/party/' + this.props.partyCode)}
        className={css`
          width: 100%;
          padding: 1rem;
          display: flex;
          box-sizing: border-box;
          background: white;
          text-decoration: none;
          color: initial;
          position: relative;
          padding: 1rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          cursor: pointer;
          @media ${bpsDown.md} {
            display: inline-block;
            width: 100%;
            padding: 0.5rem;
          }
        `}
      >
        <CardContent
          className={css`
            margin-bottom: 0;
          `}
        >
          <ImgContainer
            className={css`
              width: 5rem;
              height: 2rem;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url(${this.props.picture});
            `}
          />
          <div
            className={css`
              flex: 0 1 30%;
              width: 30%;
              overflow: hidden;
            `}
          >
            <h2
              className={css`
                white-space: nowrap;
                margin: 0;
                font-weight: 500;
                opacity: 0.8;
                text-decoration: underline;
                text-overflow: ellipsis;
              `}
            >
              {this.props.partyName}
            </h2>
          </div>
          <div
            className={css`
              flex: 5 0 5em;
            `}
          >
            <div
              className={css`
                margin-left: 1rem;
              `}
            >
              <p
                className={css`
                  margin: 0;
                  margin-top: -0.3rem;
                  text-align: right;
                `}
              >
                <b
                  className={css`
                    margin-right: 0.25rem;
                    font-size: 2rem;
                  `}
                >
                  {this.props.seats}
                </b>
                <span
                  className={css`
                    font-size: 1.44rem;
                    margin-right: 0.5rem;
                  `}
                >
                  ที่นั่ง
                </span>
              </p>
            </div>
          </div>
          <div
            className={css`
              flex: 1 0 auto;

              color: #888;
              .number {
                display: inline-block;
                font-weight: 600;
                width: 1em;
                margin-right: 0.3em;
                text-align: right;
              }
            `}
          >
            <p>
              <span className="number">{this.props.constituencySeats} </span>
              เขต
            </p>
            <p>
              <span className="number">{this.props.partylistSeats} </span>
              ปาร์ตี้ลิสต์
            </p>
          </div>
        </CardContent>
        <p
          className={css`
            background: white;
            color: black;
            border: 3px solid ${this.props.color};
            position: absolute;
            top: 0.5rem;
            font-weight: 800;
            left: 0.5rem;
            border-radius: 2rem;
            min-width: 0.75rem;
            margin: 0;
            text-align: center;
            display: inline-block;
            padding-left: 0.25rem;
            white-space: nowrap;
            margin-bottom: 0.5rem;
            padding-right: 0.25rem;
          `}
        >
          {this.props.rank}
        </p>
      </a>
    )
  }
}

export default class CardElectionContainerMobile extends Component {
  state = {
    showAll: false,
  }
  render() {
    const { ranking, overall, className } = this.props
    const { showAll } = this.state
    return (
      <div className={className}>
        <div>
          <CardElectionMobile
            className={css`
              padding: auto 5%;
            `}
            ranking={ranking}
            showAll={showAll}
            overall={overall}
          />
        </div>

        {!showAll && overall.length > 4 && (
          <ShowAllButton onClick={() => this.setState({ showAll: true })}>
            แสดงผลทั้งหมด
          </ShowAllButton>
        )}
        {showAll && (
          <ShowAllButton onClick={() => this.setState({ showAll: false })}>
            แสดงผลบางส่วน
          </ShowAllButton>
        )}
      </div>
    )
  }
}
