export function isElementVisible(id, _holder) {
  const el = document.getElementById(id)
  const holder = _holder || document.body
  const { top, bottom, left, right, width, height } = el.getBoundingClientRect()
  const holderRect = holder.getBoundingClientRect()

  return (
    (top <= holderRect.top
      ? holderRect.top - top <= height
      : bottom - holderRect.bottom <= height) &&
    (left <= holderRect.left
      ? holderRect.left - left <= width
      : right - holderRect.right <= width)
  )
}

export default isElementVisible
