import styled from '@emotion/styled'
import { css } from 'emotion'
import * as d3 from 'd3'
import React, { Component } from 'react'
import SVG from 'react-inlinesvg'
import map from '../THMap.svg'
import MediaQuery from 'react-responsive'
import contrast from 'contrast'
import ReactTooltip from 'react-tooltip'
import { bpsUp } from '../styles/const'
import SinglePolygon from '../components/SinglePolygon'
import { Icon } from 'antd'

const Wrapper = styled.div`
  height: 100%;
  text-align: center;
  overflow: hidden;
  .thai-map {
    height: 100%;
  }
  #map-tooltip.__react_component_tooltip.type-light {
    padding: 0;
  }
  #map-tooltip.__react_component_tooltip.type-light:after {
    border-top-color: ${props => props.tooltipColor};
  }
  * {
    font-family: 'Sarabun';
    font-weight: normal;
  }
  .thai-map polygon {
    cursor: pointer;
  }
`
const ZoneTooltipWrapper = styled.div`
  font-weight: 900;
  color: #999;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  p {
    margin: 0;
  }
  .top {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    border-radius: 9px 9px 0 0;
    align-items: center;
    .left {
      margin-right: 20px;
      font-size: 18px;
      text-align: left;
    }
    .districts {
      font-size: 20px;
      color: #000;
      span {
        display: block;
        margin-top: 0.5rem;
        font-size: 0.85rem;
      }
    }
    .province {
      font-size: 0.8rem;
    }
    .districtNo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .districtNoTitle {
        font-size: 12px;
        font-weight: 500;
        color: #999;
        margin-bottom: 4px;
      }
    }
  }
  .inner {
    font-family: Tahoma;
  }
  .bottom {
    font-size: 18px;
    padding: 10px 20px;
    display: flex;
    background-color: ${props => props.bottomColor};
    color: ${props =>
      contrast(props.bottomColor) === 'dark' ? '#fff' : '#000'};
    border-radius: 0 0 9px 9px;
    justify-content: space-between;
    .clickDescription {
      margin-left: 3rem;
    }
    span {
      color: ${props =>
        contrast(props.bottomColor) === 'dark'
          ? 'rgba(255,255,255,0.65)'
          : 'rgba(0,0,0,0.65)'};
    }
  }
`

const ZoneTooltip = ({ data, zone }) => (
  <ZoneTooltipWrapper
    bottomColor={zone && zone.topCandidate ? zone.topCandidate.color : '#fff'}
  >
    <div className="top">
      <div className="left">
        <p className="province">{data.provinceName}</p>
        <div className="districts">
          {zone.zoneDesc.map((z, i) => (
            <span key={i}>{z}</span>
          ))}
        </div>
      </div>
      <div className="districtNo">
        <div className="districtNoTitle">เขต</div>
        <SinglePolygon style={{ width: '70px' }} color="#E0E1E0" size={40}>
          {zone.zoneNo}
        </SinglePolygon>
      </div>
    </div>
    <div className="bottom">
      <div className="partyName">{zone.topCandidate.partyName}</div>
      <div className="clickDescription">
        <span>คลิกเพื่อดูสถิติ</span>
      </div>
    </div>
  </ZoneTooltipWrapper>
)

function endAll(transition, callback) {
  if (typeof callback !== 'function')
    throw new Error('Wrong callback in endall')
  if (transition.size() === 0) {
    callback()
  }
  var n = 0
  transition
    .each(function() {
      ++n
    })
    .on('end', function() {
      if (!--n) callback()
    })
}
function zoomed() {
  d3.select('#whole-map').attr('transform', d3.event.transform)
}
class Map extends Component {
  constructor(props) {
    super(props)
    this.polygons = {}
    this.state = { time: Date.now(), counter: 0 }
  }

  handleMouseClickPolygon = (d, i, group) => {
    if (d.topCandidates.length === 0) return
    if (this.props.mobile && !this.props.fullScreen) return
    const provinceCode = d.provinceCode
    const zoneNo = d.zoneNo
    this.props.openModal(`/${provinceCode}/${zoneNo}`)
  }

  componentDidMount() {
    // //TODO: for dev only
    // this.props.setHover(true)
    // const p = this.props.provinces[3]
    // const z = p.zones[0]
    // if (z.topCandidates && z.topCandidates.length > 0) {
    //   z.topCandidate = z.topCandidates[0]
    // }
    // z.province = p
    // this.props.setZone(z)
  }
  componentDidUpdate(prevProps, prevState) {
    this.update()
    if (!prevProps.isFocus && this.props.isFocus) {
      this.play()
    }

    if (!prevProps.fullScreen && this.props.fullScreen) {
      this.enableTouchZoom()
    }

    if (prevProps.fullScreen && !this.props.fullScreen) {
      this.disableTouchZoom()
    }
  }

  enableTouchZoom = () => {
    this.zoom = d3.zoom()
    d3.select('.thai-map').call(this.zoom)
    this.zoom.on('zoom', zoomed)
  }

  disableTouchZoom = () => {
    d3.select('.thai-map').on('.zoom', null)
    this.zoom.on('zoom', null)
  }

  update = () => {
    const zones = this.props.provinces.reduce((prev, cur) => {
      return prev.concat(
        cur.zones.map(z => ({
          ...z,
          provinceCode: cur.provinceCode,
          provinceName: cur.provinceName,
          id: `${cur.provinceCode}:${z.zoneNo}`,
          color:
            z &&
            z.topCandidates &&
            z.topCandidates.length > 0 &&
            z.topCandidates[0].color
              ? z.topCandidates[0].color
              : '#dddddd',
        })),
      )
    }, [])
    const groups = d3
      .select('.thai-map')
      .selectAll('g')
      .data(zones, function(d) {
        return this.id || d.id
      })
    groups
      .attr('data-for', 'map-tooltip')
      .attr('data-tip', d => d.id)
      .select('text')
      .style('fill', d => (contrast(d.color) === 'light' ? 'black' : 'white'))
    groups.select('polygon').style('fill', d => d.color)
    groups.select('polygon').on('click', this.handleMouseClickPolygon)
    ReactTooltip.rebuild()
  }

  play = () => {
    this.playing = true
    d3.select('.thai-map')
      .selectAll('g#provinces g')
      .interrupt()
    d3.select('.thai-map')
      .selectAll('g#decorators text')
      .interrupt()
    d3.select('.thai-map')
      .selectAll('g#line')
      .interrupt()
    d3.select('.thai-map')
      .selectAll('g#provinces g')
      .attr('transform', (d, i, g) => {
        const t = d3
          .select(g[i])
          .select('text')
          .attr('transform')
        const match = t.match(/translate\((.+) (.+)\)/i)
        const x = parseFloat(match[1])
        const y = parseFloat(match[2])
        return `translate(${x} ${y}) scale(0)`
      })
      .transition()
      .ease(d3.easeBounceInOut)
      .delay((d, i, g) => {
        const t = d3
          .select(g[i])
          .select('text')
          .attr('transform')
        const match = t.match(/translate\((.+) (.+)\)/i)
        const y = parseFloat(match[2])
        return y / 1.5 + Math.random() * 200
      })
      .duration(600)
      .attr('transform', (d, i) => `translate(0 0) scale(1)`)
      .call(endAll, () => {
        this.playing = false
      })

    d3.select('.thai-map')
      .selectAll('g#decorators text')
      .style('opacity', 0)
      .transition()
      .delay((d, i, g) => {
        const t = d3.select(g[i]).attr('transform')
        const match = t.match(/translate\((.+) (.+)\)/i)
        const y = parseFloat(match[2])
        return 500 + y / 1.5
      })
      .duration(600)
      .style('opacity', 1)
    d3.select('.thai-map')
      .selectAll('g#line')
      .style('opacity', 0)
      .transition()
      .delay(2000)
      .duration(600)
      .style('opacity', 1)
  }

  setupZoom = () => {
    const node = d3.select('.thai-map').node()
    const zoomFactor = 2
    if (this.props.mobile) {
    } else {
      d3.select('.thai-map')
        .on('mouseenter', e => {
          if (this.playing) return
          d3.select('#whole-map')
            .transition()
            .duration(500)
            .attr('transform', `scale(${zoomFactor})`)
        })
        .on('mouseleave', e => {
          if (this.playing) return
          d3.select('#whole-map')
            .transition()
            .duration(500)
            .attr('transform', 'scale(1)')
        })
        .on('mousemove', e => {
          if (this.playing) return
          const [x, y] = d3.mouse(node)
          const { width, height } = node.getBoundingClientRect()
          const translateX = (x - width / 2) * (zoomFactor - 1 + 0.2)
          const translateY = (y - height / 2) * (zoomFactor - 1 + 0.2)
          d3.select('#whole-map')
            .transition()
            .duration(50)
            .attr(
              'transform',
              `translate(${-translateX} ${-translateY}) scale(${zoomFactor})`,
            )
        })
    }
  }

  onSvgLoad = e => {
    this.update()
    this.play()
    this.setupZoom()
  }

  getZone = id => {}

  render() {
    const {
      zone,
      setZone,
      setHover,
      mobile,
      fullScreen,
      setFullScreen,
    } = this.props
    return (
      <Wrapper
        ref={element => {
          this.wrapper = element
        }}
        tooltipColor={
          zone && zone.topCandidate ? zone.topCandidate.color : 'transparent'
        }
      >
        {mobile && fullScreen && (
          <button
            className={css`
              position: absolute;
              right: 1rem;
              top: 1rem;
              padding: 10px 15px;
              padding-top: 8px;
              font-weight: 900;
              border-radius: 10px;
              color: white;
              border: 0;
              background: #2590d2;
            `}
            onClick={() => {
              if (fullScreen) {
                setFullScreen(false)
                d3.select('#whole-map')
                  .transition()
                  .duration(500)
                  .attr('transform', '')
              }
            }}
          >
            <Icon
              type="close"
              style={{ fontSize: '12px' }}
              className={css`
                margin-right: 5px;
                display: inline-block;
              `}
            />
            ปิด
          </button>
        )}
        <SVG
          uniquifyIDs={false}
          src={map}
          onLoad={this.onSvgLoad}
          ref={element => {
            this.map = element
          }}
        />
        <MediaQuery query={bpsUp.sm}>
          <ReactTooltip
            id="map-tooltip"
            getContent={id => {
              if (id === null || id === '___') return ''
              const provinces = this.props.provinces
              const match = id.match(/(.+):(.+)/i)
              const provinceCode = match[1]
              const zoneNo = match[2]
              const province = provinces.find(
                p => p.provinceCode === provinceCode,
              )
              const zone = province.zones.find(
                z => z.zoneNo.toString() === zoneNo,
              )
              if (zone.topCandidates.length === 0) return ''
              if (zone.topCandidates && zone.topCandidates.length > 0) {
                zone.topCandidate = zone.topCandidates[0]
              } else {
                zone.topCandidate = { color: 'transparent' }
              }
              zone.province = province
              if (
                provinceCode !== zone.province.provinceCode ||
                zoneNo !== zone.zoneNo
              ) {
                setZone(zone)
              }
              return (
                <ZoneTooltip
                  zone={zone}
                  data={{
                    provinceName: province.provinceName,
                    zoneNo: zone.zoneNo,
                    zoneDesc: zone.zoneDesc.join(' • '),
                  }}
                />
              )
            }}
            afterHide={e => {
              setHover(false)
            }}
            afterShow={e => {
              setHover(true)
            }}
            effect="solid"
            type="light"
            place="top"
          />
        </MediaQuery>
      </Wrapper>
    )
  }
}
export default Map
