import React from 'react'
import { css } from 'emotion'
import styled from '@emotion/styled'
import PartyListPolygon from './PartyListPolygon'
import { bpsDown, bpsUp } from '../../styles/const'
import { pushRoute } from '../../routing.js'

const EntryContainer = styled.div`
  cursor: pointer;
  :hover {
    box-shadow: 0 8px 20px 0 rgba(88, 88, 88, 0.2);
  }
  position: relative;
  display: grid;
  font-weight: 600;
  grid-template-columns: 6rem 20rem auto;
  @media ${bpsDown.lg} {
    min-height: unset;
    grid-template-columns: 20% 35% 45%;
  }
  > * {
    padding: 0.75rem 0 0.75rem 0.75rem;
    @media ${bpsUp.lg} {
      padding: 1.5rem 0 1.5rem 1.5rem;
    }
  }
  .polygon {
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    @media ${bpsUp.lg} {
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    }
  }
  min-height: 6rem;
  .name-party {
    color: #7d7d7d;
  }
  .number {
  }
  .img-container {
    position: relative;
    display: flex;
    padding-left: 30%;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      object-position: center;
    }
  }
  .icon {
    color: #7d7d7d;
    font-size: 21px;
    height: 21px;
    text-align: center;
  }
  .polygon,
  .icon {
    align-self: center;
  }
  ::before {
    content: '';
    position: absolute;
    height: 100%;
  }
  ::after {
    content: '';
    width: 90%;
    grid-column: 1 / 5;
    grid-row: 2;
    border-bottom: 1px #eee solid;
    margin: 0 auto;
  }
`

const Entry = props => {
  const { className, data } = props
  const { partyCode, partyName, picture, color, seats: number } = data
  return (
    <EntryContainer
      className={className}
      onClick={() => pushRoute('/party/' + partyCode)}
    >
      <div className="img-container">
        <div
          className={css`
            position: relative;
            flex: 1;
          `}
        >
          <div
            className={css`
              background-image: url(${picture});
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            `}
          />
        </div>
      </div>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 15%;
        `}
      >
        <h3
          className={css`
            line-height: 0.9em;
            font-weight: 500;
            opacity: 0.8;
            @media ${bpsUp.lg} {
              font-size: 2.2rem;
            }
          `}
        >
          {partyName}
        </h3>
        <h2
          className={css`
            opacity: 0.85;
            @media ${bpsUp.lg} {
              font-size: 2rem;
            }
          `}
        >
          {number}{' '}
          <span
            className={css`
              font-size: 0.75em;
              font-weight: 400;
            `}
          >
            ที่นั่ง
          </span>
        </h2>
      </div>
      <div className="polygon">
        {number > 0 && (
          <PartyListPolygon
            partyCode={partyCode}
            partyName={partyName}
            number={number}
            color={color || 'black'}
          />
        )}
      </div>
    </EntryContainer>
  )
}

export default Entry
