import React from 'react'
import cleverseLogoWhite from './assets/cleverse-logo-white.png'
import { css } from 'emotion'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 5px;
  margin-top: 11px;
`

const CraftByCleverse = ({ className }) => (
  <Container className={className}>
    <span
      className={css`
        color: white;
        font-weight: 600;
        font-size: 14px;
        margin-right: 5px;
      `}
    >
      พัฒนาโดย
    </span>
    <a
      target="_blank noopener noreferrer"
      href="https://www.facebook.com/cleverse.team"
    >
      <span
        className={css`
          position: relative;
        `}
      >
        <img src={cleverseLogoWhite} alt="cleverse logo" height="30" />
      </span>
    </a>
  </Container>
)

export default CraftByCleverse
