import React from 'react'
import { withRouter } from 'react-router-dom'
import { isDisabledTrack } from './tracker'
// import { EVENT_PAGE_VIEW } from './utils/event'
const GA_TRACKING_ID = 'UA-96149023-9'

export const TrackPageView = withRouter(
  class TrackPageView extends React.Component {
    componentDidMount() {
      // console.log('[TrackPageView] componentDidMount()')
      this.trackPageview()
    }
    componentDidUpdate(nextProps) {
      // console.log('[TrackPageView] componentDidUpdate()')
      if (nextProps.location !== this.props.location) {
        this.trackPageview()
      }
    }
    loadGA() {
      if (isDisabledTrack()) {
        return
      }
      return import('react-ga')
    }
    async trackPageview() {
      if (isDisabledTrack()) {
        return
      }
      if (this.props.location.pathname.includes('/redirect')) {
        // not track redirect event, it cause bounce rate stats error
        return
      }
      //   track(EVENT_PAGE_VIEW, {
      //     pathname: this.props.location.pathname,
      //     ...queryParams,
      //   })
      const ReactGA = await this.loadGA()
      ReactGA.initialize(GA_TRACKING_ID)
      window.props = this.props
      const { pathname, search, hash } = this.props.location
      // console.log(
      //   '[ga]',
      //   '[pathname]',
      //   pathname,
      //   '[search]',
      //   search,
      //   '[hash]',
      //   hash,
      // )
      const path = `${pathname}${search}${hash}`
      // console.log('[ga-path]', path)
      ReactGA.pageview(
        path,
        // this.props.location.pathname + this.props.location.search,
      )
    }
    render() {
      return null
    }
  },
)
