import React from 'react'
// import LogRocket from 'logrocket'
import qs from 'qs'
import { omitBy, isUndefined } from 'lodash'

export const isDisabledTrack = () => false
const Render = ({ children, ...props }) => children(props)
const _waitTracking = []
// let mixpanel = null
let tracker = null
let defaultParams = {}
let ReactGA

const GA_TRACKING_ID = 'UA-96149023-10'

export const setTrackerDefaultParams = p => {
  defaultParams = p
}
export const addDefaultTrackingParams = params => {
  defaultParams = { ...defaultParams, ...omitBy(params, isUndefined) }
}
export const removeDefaultTrackingParams = fields => {
  // console.log('removeDefaultTrackingParams', fields)
  fields.forEach(field => {
    delete defaultParams[field]
  })
}
export const getVariant = () => {
  // console.log('defaultParams', defaultParams)
  const { variant } = qs.parse(decodeURIComponent(defaultParams.utm_term))
  return variant
}
class UniversalTracker {
  identify(id) {
    // mixpanel.identify(id)
    // window.amplitude.getInstance().setUserId(id)
  }
  setDeviceId(deviceId) {
    if (deviceId) {
      // mixpanel.identify(deviceId)
      //   window.amplitude.getInstance().setDeviceId(deviceId)
    }
  }
  track(eventName, params, callback = () => {}) {
    if (isDisabledTrack()) {
      return
    }
    // const mergeParams = {
    //   eventSource: 'wp-election2019',
    //   ...defaultParams,
    //   ...params,
    // }
    // mixpanel.track(eventName, mergeParams)
    // return window.amplitude.logEvent(eventName, mergeParams, callback)
  }
  trackPageview(pathname, params) {
    if (isDisabledTrack()) {
      return
    }
    // mixpanel.track(EVENT_PAGE_VIEW + ':' + pathname, params)
    // window.amplitude.logEvent(EVENT_PAGE_VIEW + ':' + pathname, params)
    ReactGA.pageview(pathname + window.location.search)
  }
  alias(idA, idB) {
    // return mixpanel.alias(idA, idB)
  }
  register(userInfo) {
    // window.amplitude.getInstance().setUserProperties(userInfo)
    // return mixpanel.register(userInfo)
  }
  setUserProperties(props) {
    // mixpanel.people.set(props)
    // window.amplitude.getInstance().setUserProperties(props)
  }
  logout() {
    // window.amplitude.getInstance().setUserId(null)
    // mixpanel.reset()
  }
}
// const loadMixpanel = () => import('mixpanel-browser')
const loadReactGA = () => import('react-ga')
// always load mixpanel dynamic
export const loadTracker = async () => {
  if (isDisabledTrack()) {
    return
  }
  if (tracker) {
    return tracker
  }
  //   window.amplitude.getInstance().init(process.env.AMPLITUDE_KEY, null, {
  //     includeUtm: true,
  //     includeReferrer: true,
  //   })
  //   mixpanel = await loadMixpanel()
  // mixpanel.init(process.env.MIXPANEL_TOKEN)
  // console.log('mixpanel init')
  //   LogRocket.getSessionURL(sessionURL => {
  //     // mixpanel.track('LogRocket', { sessionURL })
  //   })
  ReactGA = await loadReactGA()
  ReactGA.initialize(GA_TRACKING_ID)
  tracker = new UniversalTracker()
  execWaitTracking()
  return tracker
}
function execWaitTracking() {
  for (let i = 0, length = _waitTracking.length; i < length; i++) {
    const params = _waitTracking.shift()
    track(...params)
  }
}
export function track(...params) {
  if (isDisabledTrack()) {
    return
  }
  if (!tracker) {
    _waitTracking.push(params)
    return
  }
  const [eventName, properties = {}] = params
  // console.log('track', eventName)
  //   LogRocket.track(eventName)
  tracker.track(eventName, properties)
}
export function instrument(trackKey, fn, params) {
  return (...args) => {
    // console.log('track', trackKey)
    track(trackKey, params)
    if (fn) {
      fn(...args)
    }
  }
}
export const withTracker = Component =>
  class ComponentWithTracker extends React.Component {
    componentDidMount() {
      // load mixpanel
      loadTracker().catch(err => {
        console.warn('load mixpanel failed', err)
      })
    }
    render() {
      return (
        <Component
          {...this.props}
          track={track}
          //   mixpanel={mixpanel}
          loadTracker={loadTracker}
        />
      )
    }
  }
export const Tracker = withTracker(Render)
// auto track when rendered
export const Track = withTracker(
  class Track extends React.Component {
    componentDidMount() {
      this.props.track(this.props.eventName, this.props.properties)
    }
    render() {
      return null
    }
  },
)
