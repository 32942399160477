import styled from '@emotion/styled'
import { bpsDown } from '../styles/const'

let Template = {}
Template.Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  @media ${bpsDown.lg} {
    flex-direction: column;
  }
`
Template.Item = styled.div`
  flex-grow: 1;
`
Template.Left = styled.div`
  order: -1;
  flex-basis: 35%;
  flex-grow: 0;
  @media ${bpsDown.lg} {
    flex-basis: auto;
  }
`
Template.Center = styled(Template.Item)`
  order: 0;
  flex-basis: 35%;
  flex-grow: 0;
  @media ${bpsDown.lg} {
    flex-basis: auto;
  }
`
Template.Right = styled(Template.Item)`
  order: 1;
  flex-basis: 30%;
  flex-grow: 0;
  @media ${bpsDown.lg} {
    flex-basis: auto;
  }
`
export default Template
