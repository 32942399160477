import React, { Component } from 'react'
import styled from '@emotion/styled'
import { MaxWidth } from './const'
import { css } from 'emotion'
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-weight: 600;

  .left {
    margin-right: 15px;
    flex: 1 1 auto;
  }
  .number {
    text-align: right;
    white-space: nowrap;
    flex: 1 0 auto;
  }
`

class HeadlineSm extends Component {
  render() {
    const { title, number, className, prefix, remark, unit } = this.props
    if (!number) return null
    return (
      <MaxWidth
        className={css`
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;
        `}
      >
        <Container className={className}>
          <div className="left">
            <h1
              className={css`
                font-weight: 500;
                line-height: 0.95em;
              `}
            >
              {prefix || ''}ผลเลือกตั้ง ส.ส. <wbr />
              {title}
            </h1>
            <p
              className={css`
                font-weight: 400;
                opacity: 0.5;
                margin-top: 0.25rem;
              `}
            >
              {remark || '*ข้อมูลที่ท่านเห็นอยู่นี้เป็นข้อมูลตรงจากกกต.'}
            </p>
          </div>
          <h1
            className={css`
              margin: 0;
              white-space: nowrap;
            `}
          >
            {number} {unit || 'ที่นั่ง'}
          </h1>
        </Container>
      </MaxWidth>
    )
  }
}

export default HeadlineSm
