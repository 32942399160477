import React from 'react'
import axios from 'axios'
import cheerio from 'cheerio'
import { css } from 'emotion'
import styled from '@emotion/styled'
import { MaxWidthLG } from '../components/const'
import newsSvg from '../components/assets/news-svg.svg'

import number62Svg from '../components/assets/62-svg.svg'

const BigText = styled.div`
  position: relative;
  display: inline-block;
  text-align: center;

  pointer-events: none;
  opacity: 1;

  img {
    width: 100%;
    max-width: 20rem;
    margin: auto;
    display: block;
  }
`

class Cards extends React.Component {
  render() {
    return (
      <div className={css``}>
        {this.props.data.map((at, i) => {
          return (
            <a
              key={at.id}
              className={css`
                display: block;
                margin-top: 6rem;
                text-decoration: none;
              `}
              href={at.link}
            >
              <div
                className={css`
                  background: black;
                  border-radius: 1rem;
                  overflow: hidden;
                  transform-origin: 0 0;
                  box-shadow: 0px 0px 90px black;
                `}
              >
                <div
                  ref={at.id + '_imageEffect'}
                  className={css`
                    transform-origin: 50% 50%;
                    transform: translate3d(0, 0, 0);
                    position: relative;
                  `}
                >
                  <div
                    ref={at.id}
                    className={css`
                      background-size: cover;
                      background-image: url(${at.image});
                      background-repeat: no-repeat;
                      width: 100%;
                      padding-top: 50%;
                    `}
                  />
                </div>
              </div>
              <div
                className={css`
                  text-align: left;
                  overflow: visible;
                  max-width: 30rem;
                  margin: auto;
                  margin-top: 2rem;
                  position: relative;
                  & > * {
                    text-decoration: none;
                    color: white;
                  }
                `}
              >
                <h1 ref={at.id + '_title'} className={'display'}>
                  {at.title}
                </h1>
                <h2
                  ref={at.id + '_description'}
                  className={css`
                    font-weight: 400;
                    opacity: 0.6;
                  `}
                >
                  {at.description}
                </h2>
              </div>
            </a>
          )
        })}

        <a
          className={css`
            display: block;
            color: white;
            position: relative;
            margin-top: 6rem;
            text-align: center;
            border: 1px solid;
            line-height: 4rem;
            font-size: 1.5rem;
            text-decoration: none;
            font-weight: bold;
          `}
          href="https://workpointnews.com/category/politics/election/"
        >
          {' '}
          ดูเพิ่มเติม
        </a>
      </div>
    )
  }
}

export default class NewsPage extends React.Component {
  constructor() {
    super()
    this.state = {
      data: false,
    }
  }
  componentDidMount() {
    this.queryData()
    document.body.setAttribute('darkMode', false)
  }
  queryData() {
    axios
      .get('https://workpointnews.com/category/politics/election/?all=true')
      .then(response => {
        if (response.status !== 200) {
          this.queryData()
          return
        }
        //Traverse and set to json
        const $ = cheerio.load(response.data)
        const json = $('.oxide-card')
          .map((i, d) => {
            const $d = $(d)
            return {
              id: $d.attr('data-id'),
              image: $d.find('.oxide-image img').attr('src'),
              title: $d.find('.oxide-card__title').text(),
              description: $d.find('.oxide-card__content').text(),
              date: $d.find('.oxide-card__date-span').text(),
              link: $d.attr('href'),
            }
          })
          .toArray()
          .slice(0, 10)
        this.setState({
          data: json,
        })
      })
  }
  render() {
    if (this.props.loading || !this.state.data) {
      return <div />
    }
    return (
      <div
        className={css`
          position: relative;
          width: 100%;
          min-height: 100vh;
          padding-top: 100vh;
          padding-bottom: 5rem;
        `}
      >
        <div>
          <div
            className={css`
              background: linear-gradient(-180deg, #f2f1f000, #000 100%);
              position: absolute;
              top: 0;
              height: 100vh;
              width: 100%;
            `}
          />
          <div
            className={css`
              background: #000;
              position: absolute;
              top: 100vh;
              bottom: 0;
              width: 100%;
            `}
          />
        </div>
        <BigText
          className={css`
            width: 85%;
            position: absolute;
            top: 50vh;
            left: 50%;
            transform: translate(-50%, -50%);
          `}
          ref={'big'}
        >
          <div ref={'bigPre'}>
            <img
              alt="news svg"
              className={css`
                margin-bottom: 2rem;
                display: block;
              `}
              src={newsSvg}
            />
          </div>
          <div ref={'bigText'}>
            <img alt="number 62" src={number62Svg} />
          </div>
        </BigText>
        {this.state.data ? (
          <MaxWidthLG>
            <Cards
              className={css`
                z-index: 1;
              `}
              isFocus={this.props.isFocus}
              data={this.state.data}
            />
          </MaxWidthLG>
        ) : (
          false
        )}
      </div>
    )
  }
}
