import React, { useState } from 'react'
import CardElectionContainer from '../components/District/CardElectionContainer'
import CardElectionContainerMobile from '../components/District/CardElectionContainerMobile'
import HeadlineSm from '../components/HeadlineSm'
import { headlineProps } from '../App'
import Template from '../components/Template'
import Map from '../components/Map'
import { pushRoute } from '../routing.js'
import { bpsDown, bpsUp } from '../styles/const'
import { css } from 'emotion'
import MediaQuery from 'react-responsive'
import { HEADER_HEIGHT } from '../components/const'

const DistrictPage = ({ loading, error, data, isFocus }) => {
  // const [isOpenModal, setIsOpenModal] = useState(false)
  const [zone, setZone] = useState(null)
  const [hover, setHover] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  if (!data) {
    return 'Loading...'
  }
  return (
    <>
      <Template.Container
        className={css`
          @media ${bpsDown.lg} {
            z-index: 2;
            display: flex;
            flex-direction: row;
          }

          @media ${bpsDown.md} {
            overflow: auto;
            display: block;
            position: relative;
            z-index: 2;
          }

          background-size: 80% auto;
          background-repeat: no-repeat;
          background-position-x: center;
          background-position-y: 5vh;
        `}
      >
        <Template.Left />
        <MediaQuery query={bpsUp.lg}>
          <Template.Center
            className={css`
              padding-top: 4rem;
              padding-bottom: 4rem;
            `}
          >
            <Map
              provinces={data.map.provinces || []}
              openModal={params => pushRoute('/district' + params)}
              isFocus={isFocus}
              zone={zone}
              setZone={setZone}
              setHover={setHover}
            />
          </Template.Center>
        </MediaQuery>
        <MediaQuery query={bpsDown.lg}>
          <div
            onClick={() => {
              if (!fullScreen) setFullScreen(true)
            }}
            className={css`
              @media ${bpsUp.md} {
                height: calc(100vh - 5rem);
                max-width: 60%;
                padding: 1rem;
                padding-left: 2rem;
                margin-bottom: 1rem;
                flex: 1;
              }
              @media ${bpsDown.md} {
                height: calc(75vh - ${HEADER_HEIGHT} - 5rem);
                margin-bottom: 1rem;
                margin-left: 10%;
              }
              @media ${bpsDown.sm} {
                margin-left: 0;
                ${fullScreen
                  ? `height: calc(100vh - ${HEADER_HEIGHT} - 5rem);`
                  : ''};
                .thai-map {
                  width: 100%;
                }
              }
            `}
          >
            <Map
              provinces={data.map.provinces || []}
              /* setIsOpenModal={setIsOpenModal} */
              openModal={params => pushRoute('/district' + params)}
              isFocus={isFocus}
              zone={zone}
              setZone={setZone}
              setHover={setHover}
              mobile
              fullScreen={fullScreen}
              setFullScreen={setFullScreen}
            />
          </div>
        </MediaQuery>
        <Template.Right
          className={css`
            display: flex;
            align-items: center;
            @media ${bpsDown.lg} {
              height: calc(66vh + 2rem);
              margin-top: 2rem;
              flex: 1;
              align-self: flex-start;
              overflow: auto;
              flex-direction: column;
              max-width: 22rem;
              padding-top: 2rem;
            }
            @media ${bpsDown.md} {
              height: auto;
              margin-top: 0;
              display: block;
              flex-grow: 1;
              max-width: none;
              overflow: auto;
            }
          `}
        >
          <MediaQuery query={bpsUp.md}>
            <div
              className={css`
                display: flex;
                align-items: center;
              `}
            >
              <CardElectionContainer
                ranking={data.map.overview.ranking || []}
                zone={zone}
                hover={hover}
                overall={data.overall || []}
              />
            </div>
          </MediaQuery>
          <MediaQuery query={bpsDown.md}>
            <HeadlineSm
              className={css`
                width: 100%;
                max-width: none;
              `}
              {...headlineProps.constituency}
            />
          </MediaQuery>
          <MediaQuery query={bpsDown.md}>
            <CardElectionContainerMobile
              className={css`
                width: 100%;
                flex: 1;
                flex: 1;
                margin-top: 1rem;
                overflow: auto;
              `}
              ranking={data.map.overview.ranking || []}
              overall={data.overall || []}
            />
          </MediaQuery>
        </Template.Right>
      </Template.Container>
    </>
  )
}

export default DistrictPage
