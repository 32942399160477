import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import cheerio from 'cheerio'
import { css } from 'emotion'
import styled from '@emotion/styled'
import { wheelingCheck,wheelStartCheck } from '../components/const'
import newsSvg from '../components/assets/news-svg.svg'
import wheel from '../components/assets/wheel.png'
import back from '../components/assets/back.svg'
import next from '../components/assets/next.svg'

import number62Svg from '../components/assets/62-svg.svg'
import moveLabelRight from '../components/assets/move-label.png'
import { isElementVisible } from '../util/isElementInView.js'

const NextPrevIcon = styled.span`
  img {
    height: 3rem;
    width: auto;
  }
  margin-left: 1rem;
  cursor: pointer;
  opacity: 0.75;
  z-index: 302;
  transition: all 0.5s;
  &:hover {
    opacity: 1;
  }
`

const BigText = styled.div`
  position: relative;
  display: inline-block;
  text-align: center;
  transform: translate(-50%, 0);
  pointer-events: none;
  margin-top: 2vw;
  opacity: 1;
  transform-style: preserve-3d;
  perspective: 100vw;
  & > div:first-of-type {
    transform: rotateY(70deg) translateZ(250px);

    opacity: 0;
  }
  & > div:last-child {
    transform: rotateY(70deg) translateZ(50px);

    opacity: 0;
  }
  & > div {
    transition: all 1.5s;
  }

  img {
    width: 20vw;
    max-width: 20rem;
    margin: auto;

    margin-bottom: 1rem;
  }
`

class Cards extends React.Component {
  constructor() {
    super()
    this.onScroll = this.onScroll.bind(this)
    this._i = 0
    this.state = {
      currentElementIndex: 0,
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.isFocus !== this.props.isFocus) {
      this.updateIsFocus(nextProps)
      this.onScroll()
    }
    if (nextState.currentElementIndex !== this.state.currentElementIndex) {
      return true
    }
    return false
  }
  componentDidMount() {
    global.isElementVisible = isElementVisible

    setTimeout(() => {
      window.addEventListener('resize', this.onScroll)
      this.updateIsFocus(this.props)
      this.onScroll()
    }, 500)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onScroll)
  }
  updateIsFocus(p) {
    if (p.isFocus) {
      this.refs.bigText.style.transform = `rotateY(0deg) translateZ(250px)`
      this.refs.bigText.style.opacity = `1`
      this.refs.bigPre.style.transform = `rotateY(0deg) translateZ(50px)`
      this.refs.bigPre.style.opacity = `1`
    } else {
      this.refs.bigText.style.transform = `rotateY(70deg) translateZ(250px)`
      this.refs.bigText.style.opacity = `0`
      this.refs.bigPre.style.transform = `rotateY(70deg) translateZ(50px)`
      this.refs.bigPre.style.opacity = `0`
    }
  }
  getScrollElement() {
    const detectorDOM = ReactDOM.findDOMNode(this.refs.detector)
    return detectorDOM.parentElement
  }
  rect(el) {
    return el.getBoundingClientRect()
  }
  clamBound(num, min, max) {
    let res = num
    if (num >= max) {
      res = (num - max) / (1 - max)
    } else if (num <= min) {
      res = (min - num) / (1 + min)
    } else {
      res = 1
    }
    if (res >= 1) {
      res = 1
    } else if (res <= 0) {
      res = 0
    }
    return res
  }
  clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num
  }
  isElementInView(rect) {
    let elemLeft = rect.left
    let elemRight = rect.right

    // Only completely visible elements return true:
    let isVisible = elemLeft >= 0 && elemRight <= window.innerWidth
    // Partially visible elements return true:
    isVisible = elemLeft < window.innerWidth && elemRight >= 0
    return isVisible
  }
  onScroll() {
    /* const sc = ReactDOM.findDOMNode(this.refs.detector).parentElement */
    /* const scrollerRect = this.rect(sc) */
    let lst = 0
    let view = false

    /* let lstDate = false */
    let firstFocus = false
    // if (isElementVisible('content-0')) {
    // }
    const elementInViewIndexes = this.props.data
      .map((__, i) => i)
      .filter(i => {
        return isElementVisible(`content-${i}`)
      })
    const currentElementIndex = _.maxBy(elementInViewIndexes, i => {
      return +document.getElementById(`news-cover-image-${i}`).style.opacity
    })
    this.setState({ currentElementIndex })

    this.props.data.forEach((d, i) => {
      const img = ReactDOM.findDOMNode(this.refs[d.id])
      const imageEffect = ReactDOM.findDOMNode(this.refs[d.id + '_imageEffect'])
      const description = ReactDOM.findDOMNode(this.refs[d.id + '_description'])
      const title = ReactDOM.findDOMNode(this.refs[d.id + '_title'])
      const rect = this.rect(img.parentElement.parentElement)
      const inView = this.isElementInView(rect)
      const big = ReactDOM.findDOMNode(this.refs['big'])
      const number = ReactDOM.findDOMNode(this.refs[d.id + 'number'])
      /* let dateBig = false
      if (this.refs[d.id + '_dateBig']) {
        dateBig = ReactDOM.findDOMNode(this.refs[d.id + '_dateBig'])
      } */

      if (inView && !view) {
        lst = 3
      }

      let center = rect.left + rect.width / 2

      let fromCenter = center - window.innerWidth / 2
      let fromCenterTl = fromCenter / (window.innerWidth / 2)

      if (i === 0) {
        big.style.transform = `  translate3d(${-fromCenter / 5}px,0,0) `
      }
      if (inView || lst) {
        img.style['background-image'] = `url(${d.image})`
        lst--

        img.parentElement.parentElement.style.transform = `  translate3d(${fromCenter /
          7}px,0,0) `
        imageEffect.style.transform = ` scale(1.5) translate3d(${(-100 *
          fromCenter) /
          window.innerWidth /
          2}%,0,0) `

        description.style.transform = `translate3d(${-fromCenter / 20}px,0,0)`
        title.style.transform = `translate3d(${fromCenter / 20}px,0,0)`

        title.style.opacity = `${0.3 + (1 - Math.abs(fromCenterTl)) * 0.7}`
        description.style.opacity = `${0.1 +
          (1 - Math.abs(fromCenterTl)) * 0.4}`
        description.style.display = 'block'
        img.style.display = 'block'
        img.style.opacity = `${Math.max(1 - Math.abs(fromCenterTl), 0.3)}`
        number.style.opacity = 1
        number.style.transform = `translate3d(${fromCenter / 3}px,0,0)`
        number.style.display = 'block'
        if (fromCenterTl < 0) {
          img.pass = true
        }
      }
      const scaleY = 0.2 + 3 - this.clamp(Math.abs(fromCenterTl), -3, 3)
      // const line = this.refs.timeLine.refs[d.id]
      
      if (scaleY > 0.20001) {
        // line.style['margin-left'] = `${0.2 +
        //   3 -
        //   this.clamp(Math.abs(fromCenterTl), -3, 3)}rem`
      }
      if (scaleY > 0.20001 && !firstFocus) {
        firstFocus = d.id

      }
      
    })
  }
  render() {
    // console.log('[render-prev]', this.state.enablePrev)
    let date = false
    return (
      <div onWheel={e => {}}>
        <div
          className={css`
            position: absolute;
            bottom: 1.5rem;
            right: 2rem;
            z-index: 9999;
          `}
        >
          <NextPrevIcon
            disabled={this.state.currentElementIndex - 1 < 0}
            onClick={() => {
              return (
                document.getElementById(
                  `content-${this.state.currentElementIndex - 1}`,
                ) &&
                document
                  .getElementById(
                    `content-${this.state.currentElementIndex - 1}`,
                  )
                  .scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center',
                  })
              )
            }}
          >
            <img alt="back button" src={back} />
          </NextPrevIcon>
          <NextPrevIcon
            onClick={() => {
              return (
                document.getElementById(
                  `content-${this.state.currentElementIndex + 1}`,
                ) &&
                document
                  .getElementById(
                    `content-${this.state.currentElementIndex + 1}`,
                  )
                  .scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center',
                  })
              )
            }}
          >
            <img alt="next button" src={next} />
          </NextPrevIcon>
        </div>
        <h4
          className={css`
            position: absolute;
            top: 0;
            line-height: 3rem;
            left: 50%;
            color: white;
          `}
        >
          Timeline ข่าวเลือกตั้ง 62
        </h4>
        
        <img
          src={moveLabelRight}
          alt="label right"
          className={css`
            opacity: 0.5;
            max-width: 6rem;
            position: absolute;
            top: 50%;
            right: 3rem;
            transform: translate(0, -50%);
          `}
        />
        <div
          className={css`
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            overflow: hidden;
            bottom: 0;
            padding-top: 20vh;
          `}
          onWheel={e => {

            
            let xmv = Math.abs(e.deltaY)>Math.abs(e.deltaX)?e.deltaY:e.deltaX
            if(wheelStartCheck(e,this) && ReactDOM.findDOMNode(this.refs.detector).parentElement.scrollLeft === 0 && xmv<0){

              return
            }
            wheelingCheck(e, this)

            let mv = Math.abs(e.deltaY) > Math.abs(e.deltaX) ? e.deltaY : e.deltaX
            if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
              if (mv < 10) mv *= 40 // normalize for Firefox
            }
            
            if (mv) {
              ReactDOM.findDOMNode(
                this.refs.detector,
              ).parentElement.scrollLeft += mv
              e.preventDefault()
              console.log('dd')
            } 

            e.stopPropagation()
          }}
          onScroll={this.onScroll}
        >
          <div
            ref={'detector'}
            className={css`
              display: inline-block;
              height: 100%;
              white-space: nowrap;
              padding-left: 30vw;
            `}
          >
            {this.props.data.map((at, i) => {
              const placeData = date !== at.date
              date = at.date

              return [
                i === 0 ? (
                  <BigText ref={'big'} key={i + '-0'}>
                    <div ref={'bigPre'}>
                      <img
                        alt="news svg"
                        className={css`
                          margin-bottom: 2rem;
                          display: block;
                        `}
                        src={newsSvg}
                      />
                    </div>
                    <div ref={'bigText'}>
                      <img alt="number 62" src={number62Svg} />
                    </div>
                  </BigText>
                ) : (
                  false
                ),
                i !== 0 && placeData ? (
                  <p
                    key={i + '-1'}
                    ref={at.id + '_dateBig'}
                    className={css`
                      display: inline-block;
                      color: white;
                      font-size: 8vw;
                      line-height: 6vw;
                      margin: 0;
                      vertical-align: top;
                      margin-left: 30vw;
                      padding-top: 6rem;
                      text-align: right;
                    `}
                  >
                    <b>{at.date}</b>
                  </p>
                ) : (
                  false
                ),
                <a
                  key={i + '-2'}
                  id={`content-${i}`}
                  className={css`
                    display: inline-block;
                    color: white;
                    white-space: normal;
                    width: 38vw;
                    vertical-align: top;
                    margin-top: 2rem;
                    padding-right: 3vw;
                    text-decoration: none;
                    position: relative;
                    min-width: 30rem;
                  `}
                  href={at.link}
                >
                  <div
                    className={css`
                      background: black;
                      border-radius: 2rem;
                      overflow: hidden;
                      transform-origin: 0 0;
                      box-shadow: 0px 0px 90px black;
                    `}
                  >
                    <div
                      ref={at.id + '_imageEffect'}
                      className={css`
                        transform-origin: 50% 50%;
                        transform: translate3d(0, 0, 0);
                        position: relative;
                      `}
                    >
                      <div
                        id={`news-cover-image-${i}`}
                        ref={at.id}
                        className={css`
                          background-size: cover;
                          background-repeat: no-repeat;
                          width: 100%;
                          padding-top: 17.5rem;
                        `}
                      />
                    </div>
                  </div>
                  <div
                    className={css`
                      text-align: left;
                      overflow: visible;
                      max-width: 30rem;
                      margin: auto;
                      margin-top: 2rem;
                    `}
                  >
                    <h1 ref={at.id + '_title'} className={'display'}>
                      {at.title}
                    </h1>
                    <h2
                      ref={at.id + '_description'}
                      className={css`
                        display: none;
                        font-weight: 400;
                        opacity: 0.6;
                      `}
                    >
                      {at.description}
                    </h2>
                    <p
                      ref={at.id + 'number'}
                      className={css`
                        display: none;
                        font-size: 4vw;
                        position: absolute;
                        margin-top: 2vw;
                        right: 4vw;
                        top: 0;
                      `}
                    >
                      {i + 1}
                    </p>
                  </div>
                </a>,
              ]
            })}
          </div>
        </div>

        <img
          src={wheel}
          alt=""
          className={css`
            bottom: 2rem;
            right: 11.5rem;
            width: 2rem;
            height: auto;
            position: absolute;
            opacity: 0.3;
            z-index: -1;
          `}
        />
      </div>
    )
  }
}

export default class NewsPage extends React.Component {
  constructor() {
    super()
    this.state = {
      data: false,
    }
  }
  componentDidMount() {
    this.queryData()
    this.updateDarkMode()
  }
  queryData() {
    axios
      .get('https://workpointnews.com/category/politics/election/?all=true')
      .then(response => {
        if (response.status !== 200) {
          this.queryData()
          return
        }
        //Traverse and set to json
        const $ = cheerio.load(response.data)
        const json = $('.oxide-card')
          .map((i, d) => {
            const $d = $(d)
            return {
              id: $d.attr('data-id'),
              image: $d.find('.oxide-image img').attr('src'),
              title: $d.find('.oxide-card__title').text(),
              description: $d.find('.oxide-card__content').text(),
              date: $d.find('.oxide-card__date-span').text(),
              link: $d.attr('href'),
            }
          })
          .toArray()
        this.setState({
          data: json,
        })
      })
  }
  componentDidUpdate() {
    this.updateDarkMode()
  }
  updateDarkMode() {
    if (this.props.isFocus) {
      document.body.setAttribute('darkMode', 'true')
    } else {
      document.body.setAttribute('darkMode', false)
    }
  }
  render() {
    return (
      <div
        className={css`
          position: absolute;
          width: 100%;
          height: 100%;
        `}
      >
        {this.state.data ? (
          <Cards isFocus={this.props.isFocus} data={this.state.data} />
        ) : (
          false
        )}
      </div>
    )
  }
}
